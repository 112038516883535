import NProgress from 'nprogress';
import { api } from '../constants/app_configuration';
import { navigate } from 'gatsby';
import types from './types';
import { apiFailure } from './error';
import { setLoading } from './loading';
import { setAuthToken } from './auth';
import { executeApiRequest } from '../helpers/api';
import { getRedirectPathFromSelectNumberPage } from '../helpers/routes';

export const validateNumberResponse = payload => ({
  type: types.VALIDATE_NUMBER,
  payload
});

const portInNumberLoading = loading => ({
  type: types.PORT_IN_NUMBER_LOADING,
  payload: loading
});

const portInNumberSuccess = ({ number, portInNumberAcknowledged, nwType }) => ({
  type: types.PORT_IN_NUMBER_SUCCESS,
  payload: {
    mode: 'portIn',
    number,
    portInNumberAcknowledged,
    nwType
  }
});

const portInNumberFailure = error => ({
  type: types.PORT_IN_NUMBER_FAILURE,
  payload: error
});

const validateNumber = (number, challengeCode, apigeeOAuthToken) => {
  const url = api.validateNumber;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      number,
      challengeCode
    })
  };

  return executeApiRequest(url, apigeeOAuthToken, options)
    .then(response => {
      const { status } = response;

      if (status === 200) {
        return response.json();
      } else {
        throw new Error(status);
      }
    })
    .then(async response => {
      return response.result;
    });
};

// NO_NUMBER_ELIGIBLE_PORTIN: '03',
// NOT_ELIGIBLE_PORTIN: '04',
// MVNO_NOT_ELIGIBLE_PORTIN: '05',
// FAILURE_SECURITY: '98',
// BACK_END_ERROR: '99'

const NETWORK_TYPE = {
  ELIGIBLE_PORTIN: '02',
  POSTPAID_NEWLY_SIGNUP: '07'
};

export const portInNumber = (
  number,
  portInNumberAcknowledged,
  selectedSimType
) => {
  const url = api.portInNumber;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      checkNwTypeReq: {
        appName: 'appgomo',
        serviceNumber: number
      }
    })
  };

  return (dispatch, getState) => {
    dispatch(portInNumberLoading(true));
    NProgress.start();
    const apigeeOAuthToken = getState().apigeeToken.token;

    return executeApiRequest(url, apigeeOAuthToken, options)
      .then(response => {
        const { status } = response;

        if (status === 200) {
          return response.json();
        } else {
          throw new Error(status);
        }
      })
      .then(async response => {
        const { nwType, authCode } = response.checkNwTypeResp;
        if (
          nwType === NETWORK_TYPE.ELIGIBLE_PORTIN ||
          nwType === NETWORK_TYPE.POSTPAID_NEWLY_SIGNUP
        ) {
          if (
            nwType === NETWORK_TYPE.POSTPAID_NEWLY_SIGNUP &&
            selectedSimType === 'HybridE'
          ) {
            dispatch(setLoading('PORT_IN_NUMBER', false));
            dispatch(portInNumberFailure({ message: nwType }));
          } else {
            // If valid
            const validationFailed = await validateNumber(
              number,
              authCode,
              apigeeOAuthToken
            ); // 0 = passed, 1 = failed
            if (validationFailed) {
              dispatch(validateNumberResponse(!!validationFailed));
              dispatch(setLoading('PORT_IN_NUMBER', false));
            } else {
              dispatch(
                portInNumberSuccess({
                  number,
                  portInNumberAcknowledged,
                  nwType
                })
              );
              dispatch(setAuthToken(authCode));

              const selectedSimType = getState().simTypeDetails.selectedSimType;
              navigate(getRedirectPathFromSelectNumberPage(selectedSimType));
            }
          }
        } else {
          dispatch(setLoading('PORT_IN_NUMBER', false));
          dispatch(portInNumberFailure({ message: nwType }));
        }
        NProgress.done();
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('PORT_IN_NUMBER', false));
        dispatch(apiFailure(error));
        NProgress.done();
      });
  };
};
