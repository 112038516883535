import {
  dataLayerPush,
  getDataLayerVariableNetworkType,
  getDatalayerVariableSimTypeValue
} from '../helpers/datalayer';
import { replaceSpaces } from '../helpers/formatter';
import { getUrlWithoutQueryParams } from '../helpers/url';

export const pushEventToDataLayer = eventData => {
  return (_, getState) => {
    const state = getState();
    const dataLayer = {
      event: eventData.event,
      eventCategory: eventData.eventCategory,
      eventAction: eventData.eventAction,
      eventLabel: replaceSpaces(eventData.eventLabel),
      networkLineType: getDataLayerVariableNetworkType(
        eventData.networkType || state.selectedNumber.mode
      ),
      simType: getDatalayerVariableSimTypeValue(
        eventData.simType || state.simTypeDetails.selectedSimType
      ),
      couponCode: eventData.couponCode || state.promoCode.details?.promoCode,
      pageUrl: getUrlWithoutQueryParams(),
      transactionId: eventData.transactionId,
      componentType: eventData.componentType,
      ...(eventData.errorName && {
        errorName: replaceSpaces(eventData.errorName)
      })
    };

    dataLayerPush(dataLayer);
  };
};
