/**
 * Return headers object after adding the authorization header
 *
 * @param {string} apigeeToken - Apigee OAuth token
 * @param {Object} [headers={}] - Existing headers object
 * @return {Promise} - Headers object added with authorization header
 */
export const constructAuthorizationHeaders = (apigeeToken, headers = {}) => {
  if (apigeeToken) {
    headers.authorization = `Bearer ${apigeeToken}`;
  }
  return headers;
};

/**
 * Make API call with fetch API with the given URL and options
 *
 * @param {string} url - URL to make the fetch API call
 * @param {string} apigeeToken - Apigee OAuth token
 * @param {Object} [options={}] - Fetch API request options
 * @return {Promise} - Fetch invocation promise
 */
export const executeApiRequest = (url, apigeeToken, options = {}) => {
  const headers = constructAuthorizationHeaders(apigeeToken, options.headers);
  options.headers = headers;

  return fetch(url, options);
};
