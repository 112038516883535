import { LOADING } from '../constants/response_types.json';

const loadingReducer = (state = {}, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(LOADING|SUCCESS|FAILURE)/.exec(type);
  // not *_LOADING / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (requestName === 'SET') {
    return { ...state, [payload.key]: payload.loading };
  }

  return { ...state, [requestName]: requestState === LOADING };
};

export default loadingReducer;
