import types from './types';

export const apiFailure = error => ({
  type: types.API_FAILURE,
  payload: error
});

export const clearError = errorKey => ({
  type: types.CLEAR_ERROR,
  payload: errorKey
});
