export const GTM_DATALAYER_EVENTS = {
  CP_PROMO_ERROR_406: 'gomo-cp-promo-error-406',
  RESERVE_NUMBER: 'gomo-select-new-number',
  PORT_IN: 'gomo-select-keep-existing',
  ORDER_INITIATE: 'gomo-order-initiate',
  DELIVERY_SCHEDULED: 'gomo-physical-sim-card-delivery-scheduled',
  SELF_COLLECTION: 'gomo-self-collection',
  MY_INFO_DATA_RETRIEVED: 'gomo-address-retrieved',
  PERSONAL_DETAILS_ENTERED: 'gomo-personal-details-entered',
  PROCEED_TO_PAY: 'gomo-proceed-to-pay',
  ORDER_COMPLETE: 'gomo-order-complete'
};

export const GTM_EVENT_ACTIONS = {
  BUTTON_CLICK: 'button-click',
  POPUP: 'gomo-popup',
  PAGE_LOAD: 'page-load'
};

export const GTM_EVENT_CATEGORIES = {
  CONTENT_CLICK: 'gomo-content-click',
  POPUP: 'gomo-popup',
  PAGE_LOAD: 'page-load'
};

export const GTM_EVENT_LABELS = {
  POPUP: 'gomo-popup',
  PAGE_LOAD: 'page-load'
};

export const GTM_EVENT_COMPONENT_TYPE = {
  POPUP: 'popup'
};

export const GTM_DATALAYER_VARIABLE_NETWORK_LINE_TYPE = {
  RESERVE_NUMBER: 'gomo-get-new-number',
  PORT_IN: 'gomo-keep-my-existing-number'
};

export const GTM_DATALAYER_VARIABLE_SIM_TYPE = {
  ESIM: 'e-sim',
  PSIM: 'physical-sim-card'
};
