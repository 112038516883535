export const ROUTES = {
  SELECT_NUMBER: '/select-number/',
  DELIVERY_DETAILS: '/delivery-details/',
  USER_DETAILS: '/user-details/',
  CONFIRM_DETAILS: '/confirm-details/',
  SUCCESS: '/success/'
};

export const PSIM_FLOW_ROUTES = [
  ROUTES.SELECT_NUMBER,
  ROUTES.DELIVERY_DETAILS,
  ROUTES.USER_DETAILS,
  ROUTES.CONFIRM_DETAILS,
  ROUTES.SUCCESS
];

export const ESIM_FLOW_ROUTES = [
  ROUTES.SELECT_NUMBER,
  ROUTES.USER_DETAILS,
  ROUTES.CONFIRM_DETAILS,
  ROUTES.SUCCESS
];
