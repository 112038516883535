import types from './types';
import { api } from '../constants/app_configuration';
import { POPSTATION_ERRORS } from '../constants/response_types.json';
import { getDistanceFromLatLonInKm } from '../helpers/geometry';
import { sanitizeString } from '../helpers/formatter';
import { getGeocodingUrl } from '../helpers/address';
import { setLoading } from './loading';
import { getDeliveryDates, setDeliveryDates } from './getDeliveryDates';
import { executeApiRequest } from '../helpers/api';

const getPopstationsLoading = loading => ({
  type: types.GET_POPSTATIONS_LOADING,
  payload: loading
});

export const getPopstationsSuccess = () => ({
  type: types.GET_POPSTATIONS_SUCCESS
});

const getPopstationsFailure = error => ({
  type: types.GET_POPSTATIONS_FAILURE,
  payload: error
});

const getPopstationsByPostcodeLoading = loading => ({
  type: types.GET_POPSTATIONS_BYPOSTCODE_LOADING,
  payload: loading
});

export const getPopstationsByPostcodeSuccess = () => ({
  type: types.GET_POPSTATIONS_BYPOSTCODE_SUCCESS
});

const getPopstationsByPostcodeFailure = error => ({
  type: types.GET_POPSTATIONS_BYPOSTCODE_FAILURE,
  payload: error
});

const setSelfCollect = isSelfCollect => ({
  type: types.SET_SELFCOLLECT,
  payload: isSelfCollect
});

const setSearchOption = isSearchByPostal => ({
  type: types.SET_SEARCHOPTION,
  payload: isSearchByPostal
});

const setSelfCollectFilter = selfCollectFilter => ({
  type: types.SET_MAPFILTER,
  payload: selfCollectFilter
});

const setActivePostCode = postcode => ({
  type: types.SET_ACTIVE_POST_CODE,
  payload: postcode
});

const setActivePopStations = location => ({
  type: types.SET_POPSTATION_BY_POSTCODE,
  payload: location
});

const resetSelfCollect = () => ({
  type: types.RESET_SELFCOLLECT
});

export const rehydrateSelfCollect = payload => ({
  type: types.REHYDRATE_SELFCOLLECT,
  payload
});

const setPopstationByPostCode = popstations => {
  const activePostalCodeCoordinates = getGeocodingUrl(popstations.postalCode);
  const filteredPopStation = [];
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'challenge-code': popstations.authToken,
      'service-number': popstations.serviceNumber
    }
  };

  return (dispatch, getState) => {
    dispatch(getPopstationsByPostcodeLoading(true));
    return executeApiRequest(
      activePostalCodeCoordinates,
      getState().apigeeToken.token,
      options
    )
      .then(resp => {
        if (resp.status === 200) {
          return resp.json();
        } else {
          throw { ...POPSTATION_ERRORS.FAILED_STATUS };
        }
      })
      .then(resp => {
        if (resp.status === 'OK') {
          const { lat, lng } = resp.results[0].geometry.location;
          const activePostcodeDetails = {
            code: parseInt(popstations.postalCode),
            lat: lat,
            lng: lng
          };
          popstations.allPopstations.map(item => {
            if (
              getDistanceFromLatLonInKm(
                lat,
                lng,
                item.Latitude,
                item.Longitude
              ) <= process.env.GATSBY_POPSTATION_RADIUS_FROM_POSTAL_IN_KM
            ) {
              filteredPopStation.push(item);
            }
          });

          //Return all if no result is nearby
          if (filteredPopStation.length) {
            dispatch(
              setActivePopStations(
                filteredPopStation.length
                  ? filteredPopStation
                  : popstations.allPopstations
              )
            );
            dispatch(setActivePostCode(activePostcodeDetails));
          } else {
            throw { ...POPSTATION_ERRORS.NO_RESULT };
          }
        } else if (resp.status === 'ZERO_RESULTS') {
          throw { ...POPSTATION_ERRORS.NO_RESULT };
        } else {
          throw { ...POPSTATION_ERRORS.FAILED_STATUS };
        }
        dispatch(getPopstationsByPostcodeSuccess());
      })
      .catch(err => {
        //dispatch error screen
        dispatch(
          getPopstationsByPostcodeFailure({
            message: err
          })
        );
        dispatch(setLoading('GET_POPSTATIONS_BYPOSTCODE', false));
      });
  };
};

const getPopstationByPostCode = popstations => ({
  type: types.GET_POPSTATION_BY_POSTCODE,
  payload: popstations
});

const setPopstationList = data => ({
  type: types.SET_POPSTATION_LIST,
  payload: data
});

const setSelectedStation = station => ({
  type: types.SET_SELECTED_POPSTATION,
  payload: station
});

const showDeliveryDates = ({ serviceNumber, token, conType, outletCode }) => {
  return async dispatch => {
    await dispatch(getDeliveryDates(serviceNumber, token, conType, outletCode));
  };
};

const fetchPopStations = (slotType, pMode) => {
  const apiUrl = api.getPopstations;

  return (dispatch, getState) => {
    //dispatch preloader
    dispatch(getPopstationsLoading(true));
    return executeApiRequest(apiUrl, getState().apigeeToken.token, {})
      .then(resp => {
        if (resp.status === 200) {
          return resp.json();
        } else {
          throw { ...POPSTATION_ERRORS.FAILED_STATUS };
        }
      })
      .then(resp => {
        //dispatch action to prepare list of popstations
        if (resp && resp.length > 0) {
          const popstations = resp.sort((a, b) => {
            const stationA = sanitizeString(a.POPStationName.trim());
            const stationB = sanitizeString(b.POPStationName.trim());
            return stationA.localeCompare(stationB);
          });
          dispatch(setPopstationList(popstations));

          //Clear prefetched delivery dates
          dispatch(setDeliveryDates([], slotType, pMode));
          dispatch(getPopstationsSuccess());
        } else {
          throw { ...POPSTATION_ERRORS.POPSTATION_FAILED };
        }
      })
      .catch(error => {
        //dispatch error screen
        dispatch(getPopstationsFailure({ message: error }));
        dispatch(setLoading('GET_POPSTATIONS', false));
      });
  };
};

export {
  setSelfCollect,
  setSearchOption,
  setSelfCollectFilter,
  setPopstationByPostCode,
  getPopstationByPostCode,
  fetchPopStations,
  setSelectedStation,
  setActivePostCode,
  showDeliveryDates,
  resetSelfCollect
};
