import { createReducer } from './index';
import types from '../actions/types';

import { SINGAPORE } from '../constants/geography.json';
const { MAP_FILTER } = SINGAPORE;

export const initialState = {
  isSelfCollection: false,
  isSearchByPostal: false,
  selfCollectFilter: MAP_FILTER.ALL,
  showMap: false,
  allPopstations: [],
  selectedPopstation: {}
};

export const setSelfCollect = (state, action) => {
  return {
    ...state,
    isSelfCollection: action.payload,
    selfCollectFilter: MAP_FILTER.ALL,
    isSearchByPostal: false,
    activePostcode: '',
    activePopstations: [],
    showMap: true
  };
};

export const setSearchOption = (state, action) => {
  return {
    ...state,
    isSearchByPostal: action.payload,
    selfCollectFilter: MAP_FILTER.ALL,
    showMap: !action.payload || false,
    activePostcode: ''
  };
};

export const setSelfCollectFilter = (state, action) => {
  return {
    ...state,
    selfCollectFilter: action.payload,
    activePostcode: ''
  };
};

export const setPopstationByPostCode = (state, action) => {
  return {
    ...state,
    activePopstations: action.payload
  };
};

export const getPopstationByPostCode = (state, action) => {
  return {
    ...state,
    activePopstations: action.payload,
    showMap: true
  };
};

export const setPopstationList = (state, action) => {
  return {
    ...state,
    allPopstations: action.payload
  };
};

export const setSelectedStation = (state, action) => {
  return {
    ...state,
    selectedPopstation: action.payload
  };
};

export const setActivePostCode = (state, action) => {
  return {
    ...state,
    activePostcode: action.payload
  };
};

export const resetSelfCollect = () => {
  return initialState;
};

export const rehydrateSelfCollect = (state, action) => {
  return action.payload;
};

export default createReducer(initialState, {
  [types.SET_SELFCOLLECT]: setSelfCollect,
  [types.SET_SEARCHOPTION]: setSearchOption,
  [types.SET_MAPFILTER]: setSelfCollectFilter,
  [types.SET_POPSTATION_BY_POSTCODE]: setPopstationByPostCode,
  [types.GET_POPSTATION_BY_POSTCODE]: getPopstationByPostCode,
  [types.SET_POPSTATION_LIST]: setPopstationList,
  [types.SET_SELECTED_POPSTATION]: setSelectedStation,
  [types.SET_ACTIVE_POST_CODE]: setActivePostCode,
  [types.RESET_SELFCOLLECT]: resetSelfCollect,
  [types.REHYDRATE_SELFCOLLECT]: rehydrateSelfCollect
});
