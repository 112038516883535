import types from '../actions/types';
import { createReducer } from './index';
import { PAGINATE_COUNT } from '../constants/app_configuration';

export const initialState = {
  data: {},
  slotType: '',
  pMode: '',
  page: PAGINATE_COUNT
};

export const setDeliveryDates = (state, action) => {
  return {
    ...state,
    data: action.payload.dates,
    slotType: action.payload.slotType,
    pMode: action.payload.pMode
  };
};

export const setDatesPage = (state, action) => {
  return { ...state, page: action.payload };
};

export const resetDeliveryDates = () => {
  return initialState;
};

export const rehydrateSelectedNumber = (_, action) => {
  return action.payload;
};

const deliveryDatesReducer = createReducer(initialState, {
  [types.SET_DELIVERY_DATES]: setDeliveryDates,
  [types.SET_DELIVERY_DATES_PAGE]: setDatesPage,
  [types.RESET_DELIVERY_DATES]: resetDeliveryDates,
  [types.REHYDRATE_DELIVERY_DATE]: rehydrateSelectedNumber
});

export default deliveryDatesReducer;
