import { api } from '../constants/app_configuration';
import types from './types';
import { apiFailure } from './error';
import { setLoading } from './loading';
import { executeApiRequest } from '../helpers/api';

/********* Numbers pages action creators *********/

export const setNumbersPage = page => ({
  type: types.SET_NUMBERS_PAGE,
  payload: page
});

/********* Get Numbers action creators *********/

const getNumbersLoading = loading => ({
  type: types.GET_NUMBERS_LOADING,
  payload: loading
});

const getNumbersSuccess = response => ({
  type: types.GET_NUMBERS_SUCCESS,
  payload: response
});

export const rehydrateSelectedNumber = payload => ({
  type: types.REHYDRATE_NUMBER,
  payload
});

const getNumbersFailure = error => ({
  type: types.GET_NUMBERS_FAILURE,
  payload: error
});

export const getNumbers = pageNo => {
  const url = api.getNumbers.replace(':page', pageNo);

  return (dispatch, getState) => {
    dispatch(getNumbersLoading(true));

    return executeApiRequest(url, getState().apigeeToken.token)
      .then(response => {
        if (response.status !== 200) {
          dispatch(getNumbersFailure({ message: response.statusText }));
          throw { message: {} };
        } else {
          return response.json();
        }
      })
      .then(response => {
        if (response) {
          // process data for state
          const numbers = response.content.map(item => item.number);
          const data = {
            numbers
          };

          dispatch(getNumbersSuccess(data));
        }
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('GET_NUMBERS', false));
        dispatch(apiFailure(error));
      });
  };
};
