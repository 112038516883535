export default {
  // eslint-disable-line
  API_FAILURE: 'API_FAILURE',
  CLEAR_ERROR: 'CLEAR_ERROR',

  GET_APIGEE_TOKEN_LOADING: 'GET_APIGEE_TOKEN_LOADING',
  GET_APIGEE_TOKEN_SUCCESS: 'GET_APIGEE_TOKEN_SUCCESS',
  GET_APIGEE_TOKEN_FAILURE: 'GET_APIGEE_TOKEN_FAILURE',

  SET_NUMBERS_PAGE: 'SET_NUMBERS_PAGE',
  GET_NUMBERS_LOADING: 'GET_NUMBERS_LOADING',
  GET_NUMBERS_SUCCESS: 'GET_NUMBERS_SUCCESS',
  GET_NUMBERS_FAILURE: 'GET_NUMBERS_FAILURE',

  RESERVE_NUMBER_LOADING: 'RESERVE_NUMBER_LOADING',
  RESERVE_NUMBER_SUCCESS: 'RESERVE_NUMBER_SUCCESS',
  RESERVE_NUMBER_FAILURE: 'RESERVE_NUMBER_FAILURE',
  RESERVE_NUMBER_CLEAR: 'RESERVE_NUMBER_CLEAR',

  PORT_IN_NUMBER_LOADING: 'PORT_IN_NUMBER_LOADING',
  PORT_IN_NUMBER_SUCCESS: 'PORT_IN_NUMBER_SUCCESS',
  PORT_IN_NUMBER_FAILURE: 'PORT_IN_NUMBER_FAILURE',
  PORT_IN_NUMBER_CLEAR: 'PORT_IN_NUMBER_CLEAR',

  GET_SIM_TYPE_DETAILS_LOADING: 'GET_SIM_TYPE_DETAILS_LOADING',
  GET_SIM_TYPE_DETAILS_SUCCESS: 'GET_SIM_TYPE_DETAILS_SUCCESS',
  GET_SIM_TYPE_DETAILS_FAILURE: 'GET_SIM_TYPE_DETAILS_FAILURE',
  SET_SELECTED_SIM_TYPE: 'SET_SELECTED_SIM_TYPE',

  GET_DELIVERY_DATES_LOADING: 'GET_DELIVERY_DATES_LOADING',
  GET_DELIVERY_DATES_SUCCESS: 'GET_DELIVERY_DATES_SUCCESS',
  GET_DELIVERY_DATES_FAILURE: 'GET_DELIVERY_DATES_FAILURE',
  SET_DELIVERY_DATES: 'SET_DELIVERY_DATES',
  RESET_DELIVERY_DATES: 'RESET_DELIVERY_DATES',
  SET_DELIVERY_DATES_PAGE: 'SET_DELIVERY_DATES_PAGE',

  RETRIEVE_ADDRESS_LOADING: 'RETRIEVE_ADDRESS_LOADING',
  RETRIEVE_ADDRESS_SUCCESS: 'RETRIEVE_ADDRESS_SUCCESS',
  RETRIEVE_ADDRESS_STATUS: 'RETRIEVE_ADDRESS_STATUS',
  RETRIEVE_ADDRESS_FAILURE: 'RETRIEVE_ADDRESS_FAILURE',
  RETRIEVE_ADDRESS_CLEAR: 'RETRIEVE_ADDRESS_CLEAR',
  RETRIEVE_DELIVERY_ADDRESS_CLEAR: 'RETRIEVE_DELIVERY_ADDRESS_CLEAR',
  RETRIEVE_BILLING_ADDRESS_CLEAR: 'RETRIEVE_BILLING_ADDRESS_CLEAR',
  RETRIEVE_DELIVERY_ADDRESS_SUCCESS: 'RETRIEVE_DELIVERY_ADDRESS_SUCCESS',
  RETRIEVE_BILLING_ADDRESS_SUCCESS: 'RETRIEVE_BILLING_ADDRESS_SUCCESS',

  SET_UPLOAD_IMAGE_PREVIEW: 'SET_UPLOAD_IMAGE_PREVIEW',
  RESET_UPLOAD_IMAGE: 'RESET_UPLOAD_IMAGE',
  CLEAR_UPLOAD_IMAGE_PREVIEW: 'CLEAR_UPLOAD_IMAGE_PREVIEW',

  UPLOAD_IMAGE_LOADING: 'UPLOAD_IMAGE_LOADING',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',

  SUBMIT_ORDER_LOADING: 'SUBMIT_ORDER_LOADING',
  SUBMIT_ORDER_SUCCESS: 'SUBMIT_ORDER_SUCCESS',
  SUBMIT_ORDER_FAILURE: 'SUBMIT_ORDER_FAILURE',

  SET_DELIVERY_DETAILS: 'SET_DELIVERY_DETAILS',
  RESET_DELIVERY_DETAILS: 'RESET_DELIVERY_DETAILS',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  RESET_USER_DETAILS: 'RESET_USER_DETAILS',
  SET_LOADING: 'SET_LOADING',
  ADD_UNAVAILABLE_NUMBER: 'ADD_UNAVAILABLE_NUMBER',

  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SAVE_QUERY_STRING: 'SAVE_QUERY_STRING',
  REMOVE_QUERY_STRING: 'REMOVE_QUERY_STRING',

  CHECK_SUBSCRIPTION_INFO: 'CHECK_SUBSCRIPTION_INFO',
  SET_SUBSCRIPTION_ERROR: 'SET_SUBSCRIPTION_ERROR',
  SET_GENERIC_ERROR: 'SET_GENERIC_ERROR',
  SET_MYINFO_ERROR: 'SET_MYINFO_ERROR',
  SET_MYINFO_MODE: 'SET_MYINFO_MODE',
  SET_PENDING_PORTIN_ERROR: 'SET_PENDING_PORTIN_ERROR',
  SET_NUMBER_NOT_FOUND_ERROR: 'SET_NUMBER_NOT_FOUND_ERROR',
  SET_BLACKLISTED_CUSTOMER_ERROR: 'SET_BLACKLISTED_CUSTOMER_ERROR',

  REHYDRATE_NUMBER: 'REHYDRATE_NUMBER',
  REHYDRATE_DELIVERY_DATE: 'REHYDRATE_DELIVERY_DATE',
  REHYDRATE_RETRIEVE_ADDRESS: 'REHYDRATE_RETRIEVE_ADDRESS',
  REHYDRATE_SELFCOLLECT: 'REHYDRATE_SELFCOLLECT',
  REHYDRATE_SIM_TYPE_DETAILS: 'REHYDRATE_SIM_TYPE_DETAILS',
  REHYDRATE_QUERYSTRING: 'REHYDRATE_QUERYSTRING',
  REHYDRATE_APIGEE_TOKEN: 'REHYDRATE_APIGEE_TOKEN',

  VALIDATE_NUMBER: 'VALIDATE_NUMBER',

  SET_SELFCOLLECT: 'SELF_COLLECT',
  RESET_SELFCOLLECT: 'RESET_SELFCOLLECT',
  SET_SEARCHOPTION: 'LOCATION_SEARCH_OPTION',
  SET_MAPFILTER: 'SET_MAPFILTER',
  SET_POPSTATION_BY_POSTCODE: 'SET_POPSTATION_BY_POSTCODE',

  GET_POPSTATION_BY_POSTCODE: 'GET_POPSTATION_BY_POSTCODE',
  SET_POPSTATION_LIST: 'SET_POPSTATION_LIST',
  SET_SELECTED_POPSTATION: 'SET_SELECTED_POPSTATION',
  SET_ACTIVE_POST_CODE: 'SET_ACTIVE_POST_CODE',

  GET_POPSTATIONS_LOADING: 'GET_POPSTATIONS_LOADING',
  GET_POPSTATIONS_SUCCESS: 'GET_POPSTATIONS_SUCCESS',
  GET_POPSTATIONS_FAILURE: 'GET_POPSTATIONS_FAILURE',

  GET_POPSTATIONS_BYPOSTCODE_LOADING: 'GET_POPSTATIONS_BYPOSTCODE_LOADING',
  GET_POPSTATIONS_BYPOSTCODE_SUCCESS: 'GET_POPSTATIONS_BYPOSTCODE_SUCCESS',
  GET_POPSTATIONS_BYPOSTCODE_FAILURE: 'GET_POPSTATIONS_BYPOSTCODE_FAILURE',

  RETRIEVE_PROMO_CODE_LOADING: 'RETRIEVE_PROMO_CODE_LOADING',
  RETRIEVE_PROMO_CODE_SUCCESS: 'RETRIEVE_PROMO_CODE_SUCCESS',
  RETRIEVE_PROMO_CODE_FAILURE: 'RETRIEVE_PROMO_CODE_FAILURE',
  RETRIEVE_PROMO_CODE_CLEAR: 'RETRIEVE_PROMO_CODE_CLEAR',

  GET_ONE_TIME_CHARGE_LOADING: 'GET_ONE_TIME_CHARGE_LOADING',
  GET_ONE_TIME_CHARGE_SUCCESS: 'GET_ONE_TIME_CHARGE_SUCCESS',
  GET_ONE_TIME_CHARGE_FAILURE: 'GET_ONE_TIME_CHARGE_FAILURE',
  SET_FINAL_PRICE: 'SET_FINAL_PRICE',

  SET_CALL_BARRING_TYPE: 'SET_CALL_BARRING_TYPE'
};
