import { PASSTYPES } from '../constants/identification_types.json';
import { isHavingLtvpDigitalPass, isLtvpPassHolder } from './identification';
import { SIM_TYPES } from '../constants/sim_types.json';

export const USER_DETAILS_FORM_ERRORS = {
  INCOMPLETE_MY_INFO_DETAILS_FOR_ESIM: 'INCOMPLETE_MY_INFO_DETAILS_FOR_ESIM',
  INCOMPLETE_MY_INFO_DETAILS_FOR_PSIM: 'INCOMPLETE_MY_INFO_DETAILS_FOR_PSIM'
};

export const isUserDataRetrievedThroughMyInfo = myinfoMode => {
  return myinfoMode === PASSTYPES.CITIZEN || myinfoMode === PASSTYPES.WORKPASS;
};

/**
 * Show LTVP card type selection section, if below conditions are met.
 * 1. user data is not retrieved through myinfo
 * 2. User is a LTVP pass holder
 */
export const shouldShowLtvpCardTypeSelectionSection = ({
  myinfoMode,
  idType
}) => {
  return (
    !isUserDataRetrievedThroughMyInfo(myinfoMode) && isLtvpPassHolder(idType)
  );
};

/**
 * Show LTVP digital pass section, if below conditions are met.
 * 1. user data is not retrieved through myinfo
 * 2. User is a LTVP pass holder
 * 3. User holds a LTVP digital pass
 */
export const shouldShowLtvpDigitalPassSection = ({
  myinfoMode,
  idType,
  ltvpCardType
}) => {
  return (
    !isUserDataRetrievedThroughMyInfo(myinfoMode) &&
    isLtvpPassHolder(idType) &&
    isHavingLtvpDigitalPass(ltvpCardType)
  );
};

/**
 * Show physical card upload section, if below conditions are met.
 * 1. user data is not retrieved through myinfo
 * 2. Id type form field is filled
 * 3. User is not a LTVP pass holder having a digital pass
 */
export const shouldShowPhysicalCardUploadSection = ({
  myinfoMode,
  idType,
  ltvpCardType
}) => {
  return (
    !isUserDataRetrievedThroughMyInfo(myinfoMode) &&
    idType &&
    !(isLtvpPassHolder(idType) && isHavingLtvpDigitalPass(ltvpCardType))
  );
};

/**
 * Show user details form if any of below conditions are met.
 * - If selected a physical SIM card
 * - Or if selected an ESIM and retrieved my info data
 *
 * @param {string} selectedSimType - Selected sim type
 * @param {boolean} retrievedMyInfoData - Flag to indicate whether my info data is retrieved
 *
 */
export const shouldShowUserDetailsForm = (
  retrievedMyInfoData,
  myInfoServiceAvailable
) => {
  return !!retrievedMyInfoData || !myInfoServiceAvailable;
};

/**
 * Check if sim type is an ESIM
 *
 * @param {string} simType - Selected sim type
 */
export const isEsim = simType => simType === SIM_TYPES.ESIM;

/**
 * Get error data to display in user details page error modal
 */
export const getUserDetailsErrorModalData = ({
  retrieveAddressError,
  addressStatus,
  subscriptionError,
  pendingPortInError,
  numberNotFoundError,
  blacklistedCustomerError,
  genericError,
  myinfoError,
  dobNricError,
  isIncompletePassCheck,
  selectedSimType
}) => {
  let header = 'OOPS';
  let message;
  let buttonText = 'OK, GOT IT';
  let error = '';

  const isEsimSelected = isEsim(selectedSimType);

  if (retrieveAddressError && addressStatus) {
    header = addressStatus.header;
    message = addressStatus.message;
  } else if (subscriptionError) {
    message =
      'You can only port in a number which is under your NRIC. Please try again!';
  } else if (pendingPortInError) {
    header = 'OH NO, ORDER FOUND';
    message =
      'Looks like another order already exists for that number. Get a new GOMO number?';
    buttonText = 'SELECT NEW NUMBER';
  } else if (numberNotFoundError || blacklistedCustomerError) {
    header = 'OH NO, SOMETHING WENT WRONG';
    message =
      "Looks like we've hit a bump with that number. Get a new GOMO number?";
  } else if (genericError) {
    message = 'OOPS. Something went wrong. Please try again.';
  } else if (isEsimSelected && myinfoError) {
    header = "Oh no, we've hit a bump!";
    message =
      'We could not retrieve your personal details from Singpass.<br/><br/>Try again. If this continues, get a physical SIM card instead.';
  } else if (!isEsimSelected && myinfoError) {
    header = "Oh no, we've hit a bump!";
    message =
      'We could not retrieve your personal details from Singpass.<br/><br/>Try again.';
  } else if (dobNricError) {
    header = "Oh no, we've hit a bump!";
    message = 'Kindly check that your date of birth matches your ID details.';
  } else if (isEsimSelected && isIncompletePassCheck) {
    error = USER_DETAILS_FORM_ERRORS.INCOMPLETE_MY_INFO_DETAILS_FOR_ESIM;
    header = 'Oh no, we’re missing some details';
    message =
      'We could not proceed with registration as your data with Singpass is incomplete.';
    buttonText = 'COMPLETE YOUR DATA';
  } else if (!isEsimSelected && isIncompletePassCheck) {
    error = USER_DETAILS_FORM_ERRORS.INCOMPLETE_MY_INFO_DETAILS_FOR_PSIM;
    header = 'Oh no, we’re missing some details';
    message =
      'We could not proceed with registration as your data with Singpass is incomplete.';
    buttonText = 'COMPLETE YOUR DATA';
  }

  return { error, header, message, buttonText };
};
