import types from './types';

export const saveQueryString = () => ({
  type: types.SAVE_QUERY_STRING
});

export const removeQueryString = name => ({
  payload: name,
  type: types.REMOVE_QUERY_STRING
});

export const rehydrateQueryString = payload => ({
  payload,
  type: types.REHYDRATE_QUERYSTRING
});
