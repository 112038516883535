import types from '../actions/types';
import { createReducer } from './index';

const initialState = {
  front: {
    imageId: '',
    error: null,
    loading: false,
    imageFile: null
  },
  back: {
    imageId: '',
    error: null,
    loading: false,
    imageFile: null
  }
};

const setImagePreview = (state, action) => ({
  ...state,
  [action.key]: {
    ...state[action.key],
    previewImgSrc: action.previewImgSrc
  }
});

const clearImagePreview = (state, action) => ({
  ...state,
  [action.key]: {
    ...state[action.key],
    previewImgSrc: null,
    imageId: null,
    imageFile: null
  }
});

const uploadImageLoading = (state, action) => {
  return {
    ...state,
    [action.payload.key]: {
      ...state[action.payload.key],
      loading: true,
      error: null,
      imageId: '',
      imageFile: null
    }
  };
};

const uploadImageSuccess = (state, action) => {
  return {
    ...state,
    [action.payload.key]: {
      ...state[action.payload.key],
      imageId: action.payload.imageId,
      loading: false,
      error: null,
      imageFile: action.payload.imageFile
    }
  };
};

const uploadImageFailure = (state, action) => {
  return {
    ...state,
    [action.payload.key]: {
      ...state[action.payload.key],
      loading: false,
      error: action.payload.message
    }
  };
};

const resetUploadImage = () => {
  return initialState;
};

export default createReducer(initialState, {
  [types.SET_UPLOAD_IMAGE_PREVIEW]: setImagePreview,
  [types.CLEAR_UPLOAD_IMAGE_PREVIEW]: clearImagePreview,
  [types.UPLOAD_IMAGE_LOADING]: uploadImageLoading,
  [types.UPLOAD_IMAGE_SUCCESS]: uploadImageSuccess,
  [types.UPLOAD_IMAGE_FAILURE]: uploadImageFailure,
  [types.RESET_UPLOAD_IMAGE]: resetUploadImage
});
