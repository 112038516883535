import types from './types';

export const setDeliveryDetails = deliveryDetails => ({
  type: types.SET_DELIVERY_DETAILS,
  payload: deliveryDetails
});

export const resetDeliveryDetails = () => ({
  type: types.RESET_DELIVERY_DETAILS
});
