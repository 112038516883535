import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';
import { executeApiRequest } from '../helpers/api';

export const setSelectedSimType = simType => ({
  type: types.SET_SELECTED_SIM_TYPE,
  payload: simType
});

export const setCallBarringType = cbt => ({
  type: types.SET_CALL_BARRING_TYPE,
  payload: cbt
});

const getSimTypeDetailsLoading = loading => ({
  type: types.GET_SIM_TYPE_DETAILS_LOADING,
  payload: loading
});

const getSimTypeDetailsSuccess = response => ({
  type: types.GET_SIM_TYPE_DETAILS_SUCCESS,
  payload: response
});

const getSimTypeDetailsFailure = error => ({
  type: types.GET_SIM_TYPE_DETAILS_FAILURE,
  payload: error
});

export const rehydrateSimTypeDetails = payload => ({
  type: types.REHYDRATE_SIM_TYPE_DETAILS,
  payload
});

export const getSimTypeDetails = () => {
  const url = api.getSimTypeDetails;

  return (dispatch, getState) => {
    dispatch(getSimTypeDetailsLoading(true));

    return executeApiRequest(url, getState().apigeeToken.token)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error({ message: response.statusText });
        }
      })
      .then(response => {
        if (response) {
          dispatch(getSimTypeDetailsSuccess(response));
        }
      })
      .catch(error => {
        dispatch(setLoading('GET_SIM_TYPES', false));
        dispatch(getSimTypeDetailsFailure(error));
      });
  };
};
