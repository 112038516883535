export const STORAGE_TYPE = {
  LOCAL_STORAGE: 'localStorage',
  SESSION_STORAGE: 'sessionStorage'
};

const getStorage = (storageType = 'localStorage') => {
  switch (storageType) {
    case STORAGE_TYPE.LOCAL_STORAGE:
      return window.localStorage;
    case STORAGE_TYPE.SESSION_STORAGE:
      return window.sessionStorage;
    default:
      return window.localStorage;
  }
};

/**
 * Serialises and saves state to the given storage type
 * Credits: Detox
 * @param {String} keyName
 * @param {Object} keyValue
 * @param {String} storageType
 */
export const setState = (keyName, keyValue, storageType = 'localStorage') => {
  try {
    const storage = getStorage(storageType);
    return storage.setItem(keyName, JSON.stringify(keyValue));
  } catch {}
};

/**
 * Retrieves and deserialises state from the given storage type
 *
 * @param {String} keyName
 * @param {String} storageType
 */
export const getState = (keyName, storageType = 'localStorage') => {
  try {
    const storage = getStorage(storageType);
    return JSON.parse(storage.getItem(keyName));
  } catch {}
};

/**
 * Deletes state from the given storage type
 *
 * @param {String} keyName
 * @param {String} storageType
 */
export const removeState = (keyName, storageType = 'localStorage') => {
  const storage = getStorage(storageType);
  return storage.removeItem(keyName);
};

export default {
  set: setState,
  get: getState,
  remove: removeState,
  STORAGE_TYPE
};
