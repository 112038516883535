import { api } from '../constants/app_configuration';

const SG_POSTAL_CODE_DIGITS = 6;

export const getGeocodingUrl = searchTerm =>
  `${api.getGeocode}?address=singapore+${searchTerm}`;

export const formatFloorAndUnit = address => {
  if (address.noUnitNumber || (!address.floorNumber && !address.unitNumber)) {
    return '';
  } else {
    return `#${address.floorNumber}-${address.unitNumber}`;
  }
};

export const formatBlkAndStreet = address => {
  return `${address.blockNumber} ${address.street}`;
};

export const checkAddress = (text, key) => {
  let error = '';
  const errorMessages = {
    floorNumber: 'Please enter a floor number.',
    unitNumber: 'Please enter a unit number.'
  };

  if (!text || text.trim() === '') {
    error = errorMessages[key];
  } else if (key === 'floorNumber' && isNaN(Number(text))) {
    error = 'Please enter numbers only.';
  }
  return error;
};

export const checkPostalCode = text => {
  let error = '';
  if (!text || text.trim() === '') {
    error = 'Please enter a postal code.';
  } else if (text.length !== SG_POSTAL_CODE_DIGITS || isNaN(Number(text))) {
    error = 'Please enter a valid postal code.';
  }
  return error;
};
