import NProgress from 'nprogress';
import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';
import { executeApiRequest } from '../helpers/api';

export const retrieveAddressLoading = loading => ({
  type: types.RETRIEVE_ADDRESS_LOADING,
  payload: loading
});

const retrieveAddressSuccess = () => ({
  type: types.RETRIEVE_ADDRESS_SUCCESS
});

const retrieveDeliveryAddressSuccess = address => ({
  type: types.RETRIEVE_DELIVERY_ADDRESS_SUCCESS,
  payload: address
});

const retrieveBillingAddressSuccess = address => ({
  type: types.RETRIEVE_BILLING_ADDRESS_SUCCESS,
  payload: address
});

const retrieveAddressFailure = error => ({
  type: types.RETRIEVE_ADDRESS_FAILURE,
  payload: error
});

export const rehydrateRetrieveAddress = payload => ({
  type: types.REHYDRATE_RETRIEVE_ADDRESS,
  payload
});

export const retrieveAddressStatus = payload => ({
  type: types.RETRIEVE_ADDRESS_STATUS,
  payload
});

export const retrieveAddressClear = () => ({
  type: types.RETRIEVE_ADDRESS_CLEAR
});

export const retrieveDeliveryAddressClear = () => ({
  type: types.RETRIEVE_DELIVERY_ADDRESS_CLEAR
});

export const retrieveBillingAddressClear = () => ({
  type: types.RETRIEVE_BILLING_ADDRESS_CLEAR
});

export const retrieveAddress = (
  postalCode,
  authCode,
  serviceNumber,
  addressType
) => {
  const url = api.retrieveAddress;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      checkAddrPostalReq: {
        appName: 'appgomo',
        postCode: postalCode,
        challengeCode: authCode,
        serviceNumber: serviceNumber
      }
    })
  };

  return (dispatch, getState) => {
    dispatch(retrieveAddressLoading(true));
    if (addressType === 'delivery') {
      dispatch(retrieveDeliveryAddressClear());
    } else if (addressType === 'billing') {
      dispatch(retrieveBillingAddressClear());
    }

    NProgress.start();

    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        if (response.status !== 200) throw { message: {} };
        else return response.json();
      })
      .then(response => {
        // Check for system and database errors
        if (response.checkAddrPostalResp.status !== '000') {
          if (response.checkAddrPostalResp.status === '102')
            dispatch(
              retrieveAddressStatus({
                header: 'OH NO, NO ADDRESS FOUND',
                message: 'Please check the postal code entered and try again.'
              })
            );
          else
            dispatch(
              retrieveAddressStatus({
                header: 'OH NO, SOMETHING WENT WRONG',
                message:
                  'Our system seems to have gone out for a moment there! Please wait for a while, then try again.'
              })
            );
          dispatch(
            retrieveAddressFailure({ message: 'Failed to retrieve address.' })
          );
          NProgress.done();
        } else {
          const addressList = response.checkAddrPostalResp.addr.map(address => {
            return {
              blk: address.blk,
              blockNumber: address.blkNo,
              street: address.street,
              unitNumbers: address.unitNumber,
              buildClass: address.buildClass
            };
          });

          const payload = addressList;

          dispatch(retrieveAddressSuccess());

          if (addressType === 'delivery') {
            dispatch(retrieveDeliveryAddressSuccess(payload));
          } else if (addressType === 'billing') {
            dispatch(retrieveBillingAddressSuccess(payload));
          }

          NProgress.done();
        }
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('RETRIEVE_ADDRESS', false));
        dispatch(
          retrieveAddressStatus({
            header: 'OH NO, SOMETHING WENT WRONG',
            message:
              'Our system seems to have gone out for a moment there! Please wait for a while, then try again.'
          })
        );
        dispatch(
          retrieveAddressFailure({ message: 'Failed to retrieve address.' })
        );
        NProgress.done();
      });
  };
};
