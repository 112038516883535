export const LP_SITE_ID = process.env.GATSBY_LP_SITE_ID;
export const LP_SECTION = process.env.GATSBY_LP_SECTION;
export const LP_ENGAGEMENT_ID = process.env.GATSBY_LP_ENGAGEMENT_ID;
export const GOMO_MOBILE_TNC = process.env.GATSBY_GOMO_MOBILE_TNC;
export const LP_DIV_ID = 'lpButtonDiv-GoMo';

export const PAGE_RANGE = 100;
export const PRODUCT_CODE = '2F3';
export const PAGINATE_COUNT = 6;

export const ENABLE_M_ID_SUPPORT = process.env.GATSBY_ENABLE_M_ID_SUPPORT;

export const THEME = 'hybrid';

const DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : process.env.GATSBY_APIGEE_END_POINT;
const BASE_SERVICE_NUMBER_URL = DOMAIN + '/api/sg/v1/gomo-service-number';
const BASE_CEGOMO_URL = DOMAIN + '/api/sg/v1/gomo-ce';
const BASE_IMAGE_URL = DOMAIN + '/api/sg/v1/gomo-image';
const BASE_PAYMENT_URL = DOMAIN + '/api/sg/v1/gomo-payment';
const BASE_POPSTATION = DOMAIN + '/api/sg/v1/gomo-popstation';
const BASE_GEOCODE = DOMAIN + '/api/sg/v1/gomo-storelocation/geocode';
const BASE_APIGEE_OAUTH = DOMAIN + '/api/sg/v1/oauth';

export const api = {
  getNumbers: BASE_SERVICE_NUMBER_URL + '/numbers/:page',
  reserveNumber: BASE_SERVICE_NUMBER_URL + '/number/reserve',
  unreserveNumber: BASE_SERVICE_NUMBER_URL + '/number/unreserve',
  rereserveNumber: BASE_SERVICE_NUMBER_URL + '/number/rereserve',

  portInNumber: BASE_CEGOMO_URL + '/checkMobileOperator',
  getDeliveryDates: BASE_CEGOMO_URL + '/getDeliveryTimeSlot',
  retrieveAddress: BASE_CEGOMO_URL + '/queryAddress',
  checkSubscriptionInfo: BASE_CEGOMO_URL + '/subscriptionInfo',
  getSimTypeDetails: BASE_CEGOMO_URL + '/sim-types',
  uploadImage: BASE_IMAGE_URL + '/public/upload',
  submitOrder: BASE_PAYMENT_URL + '/buyflow',
  validateNumber: BASE_PAYMENT_URL + '/validatenumber',
  oneTimeCharges: BASE_PAYMENT_URL + '/onetimecharges',

  getPopstations: BASE_POPSTATION + '/retrieve',
  getGeocode: BASE_GEOCODE,
  promoCode: BASE_PAYMENT_URL + '/promo/code/eligibility',
  authMyInfo: BASE_PAYMENT_URL + '/ekyc/oauth/token',
  myInfoUrl: BASE_PAYMENT_URL + '/ekyc/myinfo/url',
  customerInfo: BASE_PAYMENT_URL + '/ekyc/myinfo/customer',

  apigeeToken: BASE_APIGEE_OAUTH + '/token'
};
