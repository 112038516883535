import { api } from '../constants/app_configuration';
import { apiFailure } from './error';
import { setLoading } from './loading';
import types from './types';

const getApigeeTokenLoading = loading => ({
  type: types.GET_APIGEE_TOKEN_LOADING,
  payload: loading
});

const getApigeeTokenSuccess = response => ({
  type: types.GET_APIGEE_TOKEN_SUCCESS,
  payload: response
});

const getApigeeTokenFailure = error => ({
  type: types.GET_APIGEE_TOKEN_FAILURE,
  payload: error
});

export const rehydrateApigeeToken = payload => ({
  type: types.REHYDRATE_APIGEE_TOKEN,
  payload
});

export const getApigeeToken = successCallback => {
  const url = api.apigeeToken;
  let options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: 'grant_type=client_credentials'
  };
  /*
    This header will be injected only for Dev environment via bamboo variable.
    For Prod & Non Prod this will be injected at Imperva. 
  */
  let authorizationHeader = process.env.GATSBY_APIGEE_BASIC_OAUTH || '';

  if (authorizationHeader) {
    options.headers.authorization = `Basic ${authorizationHeader}`;
  }

  return dispatch => {
    dispatch(getApigeeTokenLoading(true));

    return fetch(url, options)
      .then(response => {
        if (response.status !== 200) {
          dispatch(getApigeeTokenFailure({ message: response.statusText }));
          throw { message: {} };
        } else {
          return response.json();
        }
      })
      .then(response => {
        if (response && response.access_token) {
          dispatch(getApigeeTokenSuccess(response.access_token));
          successCallback && successCallback();
        } else {
          throw { message: {} };
        }
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('GET_APIGEE_TOKEN', false));
        dispatch(apiFailure(error));
      });
  };
};
