import {
  getNumbers,
  setNumbersPage,
  rehydrateSelectedNumber
} from './getNumbers';
import {
  reserveNumber,
  unreserveNumber,
  rereserveNumber
} from './reserveNumber';
import { portInNumber, validateNumberResponse } from './portInNumber';
import {
  getSimTypeDetails,
  setSelectedSimType,
  rehydrateSimTypeDetails,
  setCallBarringType
} from './getSimTypeDetails';
import {
  getDeliveryDates,
  setDatesPage,
  setDeliveryDates,
  resetDeliveryDates,
  rehydrateDeliveryDates
} from './getDeliveryDates';
import {
  retrieveAddress,
  retrieveAddressClear,
  retrieveDeliveryAddressClear,
  retrieveAddressStatus,
  rehydrateRetrieveAddress
} from './retrieveAddress';
import { setDeliveryDetails, resetDeliveryDetails } from './setDeliveryDetails';
import {
  setUserDetails,
  checkSubscriptionInfo,
  setSubscriptionError,
  setGenericError,
  setMyinfoError,
  setMyinfoMode,
  setPendingPortInError,
  setNumberNotFoundError,
  setBlacklistedCustomerError,
  resetUserDetails
} from './setUserDetails';
import { setLoading } from './loading';
import {
  clearPreviewImage,
  uploadImage,
  resetUploadImage
} from './uploadImage';
import { apiFailure, clearError } from './error';
import { setAuthToken } from './auth';
import {
  saveQueryString,
  removeQueryString,
  rehydrateQueryString
} from './handleQueryString';
import { submitOrder } from './submitOrder';
import {
  setSelfCollect,
  setSearchOption,
  setSelfCollectFilter,
  setPopstationByPostCode,
  getPopstationByPostCode,
  fetchPopStations,
  setSelectedStation,
  showDeliveryDates,
  resetSelfCollect,
  rehydrateSelfCollect
} from './selfCollection';
import { useMyinfo, getMyinfoData } from './useMyinfo';
import { retrievePromoCodeClear, retrievePromoCode } from './promoCode';
import { getOneTimeCharge, setFinalPrice } from './getOneTimeCharge';
import { getApigeeToken, rehydrateApigeeToken } from './getApigeeToken';
import { pushEventToDataLayer } from './dataLayer';

export default {
  // eslint-disable-line
  getNumbers,
  reserveNumber,
  unreserveNumber,
  rereserveNumber,
  setLoading,
  setNumbersPage,
  portInNumber,
  getSimTypeDetails,
  setSelectedSimType,
  getDeliveryDates,
  retrieveAddress,
  retrieveAddressClear,
  retrieveDeliveryAddressClear,
  retrieveAddressStatus,
  useMyinfo,
  getMyinfoData,
  setDeliveryDetails,
  apiFailure,
  clearError,
  clearPreviewImage,
  checkSubscriptionInfo,
  setUserDetails,
  setSubscriptionError,
  uploadImage,
  setAuthToken,
  saveQueryString,
  removeQueryString,
  setDatesPage,
  submitOrder,
  validateNumberResponse,
  setGenericError,
  setMyinfoError,
  setMyinfoMode,
  setSelfCollect,
  setSearchOption,
  rehydrateSelectedNumber,
  rehydrateDeliveryDates,
  rehydrateRetrieveAddress,
  rehydrateSelfCollect,
  rehydrateSimTypeDetails,
  rehydrateQueryString,
  rehydrateApigeeToken,
  setSelfCollectFilter,
  setPopstationByPostCode,
  getPopstationByPostCode,
  fetchPopStations,
  setSelectedStation,
  showDeliveryDates,
  setDeliveryDates,
  setPendingPortInError,
  setNumberNotFoundError,
  setBlacklistedCustomerError,
  retrievePromoCodeClear,
  retrievePromoCode,
  getOneTimeCharge,
  setFinalPrice,
  resetDeliveryDetails,
  resetUploadImage,
  resetUserDetails,
  resetDeliveryDates,
  resetSelfCollect,
  getApigeeToken,
  pushEventToDataLayer,
  setCallBarringType
};
