import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';

import { PROMO_CODE_RESPONSE_CODES } from '../constants/response_types.json';
import { dataLayerPush } from '../helpers/datalayer';
import { GTM_DATALAYER_EVENTS } from '../constants/gtmDataLayerEvents';
import { executeApiRequest } from '../helpers/api';

const retrievePromoCodeLoading = loading => ({
  type: types.RETRIEVE_PROMO_CODE_LOADING,
  payload: loading
});

const retrievePromoCodeSuccess = promocode => ({
  type: types.RETRIEVE_PROMO_CODE_SUCCESS,
  payload: promocode
});

const retrievePromoCodeFailure = error => ({
  type: types.RETRIEVE_PROMO_CODE_FAILURE,
  payload: error
});

export const retrievePromoCodeClear = () => ({
  type: types.RETRIEVE_PROMO_CODE_CLEAR
});

export const retrievePromoCode = (
  voucherCode,
  mobileNumber,
  authToken,
  txnType
) => {
  const url = api.promoCode;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      voucherCode: voucherCode,
      mobileNumber: mobileNumber,
      txnType: txnType,
      challengeCode: authToken
    })
  };

  return (dispatch, getState) => {
    dispatch(retrievePromoCodeLoading(true));

    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        const { status } = response;
        const code =
          PROMO_CODE_RESPONSE_CODES[status] === undefined ? 'default' : status;
        if (status === 200) {
          return response.json();
        } else {
          if (status === 406) {
            dataLayerPush({
              event: GTM_DATALAYER_EVENTS.CP_PROMO_ERROR_406
            });
          }
          throw new Error(code);
        }
      })
      .then(response => {
        if (response) {
          dispatch(retrievePromoCodeSuccess(response));
        } else {
          dispatch(
            retrievePromoCodeFailure({
              message: 'Failed to validate promo code'
            })
          );
        }
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('RETRIEVE_PROMO_CODE', false));
        dispatch(retrievePromoCodeFailure(error));
      });
  };
};
