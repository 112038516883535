import { isValidNric } from '@lux/helpers';
import {
  PREFIX_OPTIONS,
  PREFIX_OPTIONS_M,
  NRIC_WEIGHT,
  NRIC_REMAINDER,
  PASSTYPES,
  LTVP_CARD_TYPES
} from '../constants/identification_types.json';
import NATIONALITIES from '../constants/nationalities.json';
import { CITIZEN_PREFIX } from '../constants/identification_types.json';

export const checkNRIC = (text, prefix) => {
  let error = '';
  const nric = prefix + text;
  if (!nric || text.trim() === '') {
    error = 'Please enter your ID no.';
  } else if (prefix.toUpperCase() === 'M' && !mNoCheckSum(text, prefix)) {
    error = 'Please enter a valid ID no.';
  } else if (prefix.toUpperCase() !== 'M' && !isValidNric(nric)) {
    error = 'Please enter a valid ID no.';
  }
  return error;
};

export const removePrefix = (id, mSupport = false) => {
  if (id) {
    const ID_PREFIX = mSupport ? PREFIX_OPTIONS_M : PREFIX_OPTIONS;
    const prefixes = ID_PREFIX.map(pf => pf.text.toUpperCase());
    const prefixFound = prefixes.find(pf => pf === id.charAt(0).toUpperCase());
    if (prefixFound) {
      return id.replace(prefixFound, '');
    }
  }

  return id;
};

export const mNoCheckSum = (text, prefix) => {
  const nric = prefix.toUpperCase() + text;

  //NO NRIC
  if (!nric) return false;

  //NRIC starts with M
  if (prefix.toUpperCase() === 'M' && text) {
    //9 characters long and last character is not a number
    //9 characters long and 2nd character to 7th should be a number
    if (
      nric.length === 9 &&
      Number(text.substring(0, 7)) &&
      isNaN(Number(text.substring(7))) &&
      isValidMNric(text.substring(0, 7), text.substring(7))
    ) {
      return true;
    }
  }

  return false;
};

const isValidMNric = (numstr, remainder) => {
  const r =
    (Array.from(numstr).reduce((prev, cur, index) => {
      return prev + Number(cur) * NRIC_WEIGHT[index];
    }, 0) +
      3) %
    11;
  return remainder.toUpperCase() === NRIC_REMAINDER[r];
};

/**
 * Check if id type belongs to an LTVP pass holder
 * @param {string} idType - Pass type
 * @returns {boolean} - Indicates whether id type belongs to an LTVP pass holder
 */
export const isLtvpPassHolder = idType => {
  return PASSTYPES.LONG_TERM_VISIT_PASS.includes(idType);
};

/**
 * Check if pass holder is having LTVP digital pass
 * @param {string} ltvpCardType - LTVP Card type
 * @returns {boolean} - Indicates whether id type belongs to an LTVP pass holder
 */
export const isHavingLtvpDigitalPass = ltvpCardType => {
  return ltvpCardType === LTVP_CARD_TYPES.DIGITAL.value;
};

/**
 * Check if given Id prefix is a Singapore Citizen Id prefix
 * @param {string} idPrefix - Id Prefix
 * @returns {boolean} - Indicates whether given Id prefix is a Singapore Citizen Id prefix
 */
export const isCitizenPrefix = idPrefix => {
  return CITIZEN_PREFIX.includes(idPrefix?.toLowerCase());
};

/**
 * Get relevant nationalities applicable for the Id Prefix
 * @param {string} idPrefix - Id Prefix
 * @returns {Object[]} - List of nationality objects applicable for the given Id prefix
 */
export const getNationalities = idPrefix => {
  if (!idPrefix) {
    return NATIONALITIES;
  }

  if (!CITIZEN_PREFIX.includes(idPrefix.toLowerCase())) {
    const foreignNationalities = NATIONALITIES.filter(
      nationality => nationality.value !== 'SG'
    );
    return foreignNationalities;
  }
  return NATIONALITIES;
};
