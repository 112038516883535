import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  data: [],
  page: 0,
  unavailableNumbers: []
};

export const setNumbersPage = (state, action) => {
  return { ...state, page: action.payload };
};

export const getNumbersSuccess = (state, action) => {
  const { numbers } = action.payload;

  return { ...state, data: numbers, page: state.page + 1 };
};

export const addUnavailableNumber = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    unavailableNumbers: [...state.unavailableNumbers, payload]
  };
};

export default createReducer(initialState, {
  [types.SET_NUMBERS_PAGE]: setNumbersPage,
  [types.GET_NUMBERS_SUCCESS]: getNumbersSuccess,
  [types.ADD_UNAVAILABLE_NUMBER]: addUnavailableNumber
});
