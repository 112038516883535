import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';
import {
  CONTRACT_TYPES,
  SLOT_TYPES
} from '../constants/transaction_codes.json';
import { POPSTATION_ERRORS } from '../constants/response_types.json';
import { executeApiRequest } from '../helpers/api';

/********* Numbers pages action creators *********/

export const setDatesPage = page => ({
  type: types.SET_DELIVERY_DATES_PAGE,
  payload: page
});

/********* Delivery dates action creators *********/
const getDeliveryDatesLoading = loading => ({
  type: types.GET_DELIVERY_DATES_LOADING,
  payload: loading
});

export const getDeliveryDatesSuccess = () => ({
  type: types.GET_DELIVERY_DATES_SUCCESS
});

const getDeliveryDatesFailure = error => ({
  type: types.GET_DELIVERY_DATES_FAILURE,
  payload: error
});

export const setDeliveryDates = (dates, slotType, pMode) => ({
  type: types.SET_DELIVERY_DATES,
  payload: {
    dates,
    slotType,
    pMode
  }
});

export const resetDeliveryDates = () => ({
  type: types.RESET_DELIVERY_DATES
});

export const rehydrateDeliveryDates = payload => ({
  type: types.REHYDRATE_DELIVERY_DATE,
  payload
});

export const getDeliveryDates = (
  serviceNumber,
  token,
  contractType,
  outletCode = null
) => {
  const url = api.getDeliveryDates;
  return (dispatch, getState) => {
    dispatch(getDeliveryDatesLoading(true));

    const deliveryReq = outletCode
      ? {
          outletCode: outletCode,
          slotType: SLOT_TYPES.POPSTATION
        }
      : {
          slotType: SLOT_TYPES.HOMEDELIVERY
        };

    let conType = '';
    switch (contractType) {
      case 'portIn':
        conType = CONTRACT_TYPES.PORT_IN;
        break;
      case 'reserveNumber':
        conType = CONTRACT_TYPES.RESERVE_NUMBER;
        break;
      default:
        return;
    }

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        getDeliveryTimeSlotReq: {
          appName: 'appgomo',
          serviceNumber,
          pMode: 'gomonoc',
          conType,
          challengeCode: token,
          ...deliveryReq
        }
      })
    };

    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        if (response.status !== 200)
          throw { ...POPSTATION_ERRORS.FAILED_STATUS };
        else return response.json();
      })
      .then(response => {
        // Check for system and database errors
        const deliveryTimeSlotStatus = response.getDeliveryTimeSlotResp.status;

        //No timeslot available
        if (deliveryTimeSlotStatus === '102') {
          //Diff error copy for popstation and home delivery
          dispatch(setDeliveryDates({}, null, null));
          throw outletCode
            ? { ...POPSTATION_ERRORS.NO_POPSTATION_TIMESLOT }
            : { ...POPSTATION_ERRORS.NO_TIMESLOT_AVAILABLE };
          //Success
        } else if (deliveryTimeSlotStatus === '000') {
          // If valid
          const {
            deliveryInfo,
            slotType,
            pMode
          } = response.getDeliveryTimeSlotResp;

          if (deliveryInfo && deliveryInfo.length) {
            // Normalise delivery dates
            const deliveryDates = deliveryInfo.reduce((accum, current) => {
              const { delDate, delTime, delCode } = current.deliveryInfo;
              if (!accum[delDate]) {
                accum[delDate] = {
                  times: [
                    {
                      time: delTime,
                      code: delCode
                    }
                  ]
                };
              } else {
                accum[delDate].times.push({
                  time: delTime,
                  code: delCode
                });
              }

              return accum;
            }, {});

            dispatch(setDeliveryDates(deliveryDates, slotType, pMode));
          } else {
            dispatch(setDeliveryDates({}, slotType, pMode));
            throw { ...POPSTATION_ERRORS.FAILED_DELIVERY_DATES };
          }
          dispatch(getDeliveryDatesSuccess());
        } else {
          throw { ...POPSTATION_ERRORS.FAILED_STATUS };
        }
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(getDeliveryDatesFailure({ message: error }));
        dispatch(setLoading('GET_DELIVERY_DATES', false));
      });
  };
};
