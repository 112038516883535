import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  address: {
    blk: '',
    blockNumber: '',
    street: '',
    unitNumbers: [],
    postalCode: '',
    floorNumber: '',
    unitNumber: '',
    noUnitDetails: false
  },
  date: '',
  time: '',
  code: '',
  slotType: '',
  pMode: ''
};

export const setDeliveryDetails = (_, action) => {
  return action.payload;
};

export const resetDeliveryDetails = () => {
  return initialState;
};

export default createReducer(initialState, {
  [types.SET_DELIVERY_DETAILS]: setDeliveryDetails,
  [types.RESET_DELIVERY_DETAILS]: resetDeliveryDetails
});
