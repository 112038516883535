import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';
import { executeApiRequest } from '../helpers/api';

const uploadImageLoading = (loading, key) => ({
  type: types.UPLOAD_IMAGE_LOADING,
  payload: {
    loading,
    key
  }
});

const uploadImageSuccess = (imageId, key, file) => ({
  type: types.UPLOAD_IMAGE_SUCCESS,
  payload: {
    imageId,
    key,
    imageFile: file
  }
});

const uploadImageFailure = (error, key) => ({
  type: types.UPLOAD_IMAGE_FAILURE,
  payload: {
    message: error.message,
    key
  }
});

const setPreviewImage = (previewImgSrc, key) => ({
  type: types.SET_UPLOAD_IMAGE_PREVIEW,
  previewImgSrc,
  key
});
export const clearPreviewImage = key => ({
  type: types.CLEAR_UPLOAD_IMAGE_PREVIEW,
  key
});

export const resetUploadImage = () => ({
  type: types.RESET_UPLOAD_IMAGE
});

export const uploadImage = (file, key, token, serviceNumber) => {
  const url = api.uploadImage;
  const keyMapping = {
    front: 'webfront',
    back: 'webback'
  };

  return (dispatch, getState) => {
    dispatch(uploadImageLoading(true, key));
    const fileSizeInMb = file.size / 1000000;

    if (fileSizeInMb > 10) {
      const error = { message: 'File size exceeded 10mb' };
      dispatch(setLoading('UPLOAD_IMAGE', false));
      dispatch(uploadImageFailure(error, key));
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('type', keyMapping[key]);
      data.append('token', token);
      data.append('serviceNumber', serviceNumber);

      const options = {
        method: 'POST',
        body: data
      };

      return readImageFile(file)
        .then(imageDataSrc => {
          imageDataSrc && dispatch(setPreviewImage(imageDataSrc, key));
          return executeApiRequest(url, getState().apigeeToken.token, options);
        })
        .then(response => {
          const { status } = response;
          if (status !== 200) {
            // dispatch(clearPreviewImage(key));
            throw { message: 'File upload failed' };
          } else {
            return response.json();
          }
        })
        .then(response => {
          if (response) {
            dispatch(uploadImageSuccess(response, key, file));
          }
        })
        .catch(error => {
          // Check for thrown errors
          dispatch(uploadImageFailure(error, key));
          dispatch(setLoading('UPLOAD_IMAGE', false));
        });
    }
  };
};

function readImageFile(file) {
  return new Promise(resolve => {
    try {
      const reader = new FileReader();
      reader.onload = e => {
        resolve(e.target.result);
      };
      reader.onabort = resolve;
      reader.onerror = resolve;
      reader.readAsDataURL(file);
    } catch (e) {
      resolve();
    }
  });
}
