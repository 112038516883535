import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  simTypes: [],
  selectedSimType: '',
  myInfoServiceAvailable: true
};

export const setSimTypeDetails = (state, action) => {
  return {
    ...state,
    simTypes: action.payload.simTypes,
    myInfoServiceAvailable: action.payload.myInfoServiceAvailable
  };
};

export const setSelectedSimType = (state, action) => {
  return { ...state, selectedSimType: action.payload };
};

export const rehydrateSimTypeDetails = (_, action) => {
  return action.payload;
};

const simTypeDetailsReducer = createReducer(initialState, {
  [types.GET_SIM_TYPE_DETAILS_SUCCESS]: setSimTypeDetails,
  [types.SET_SELECTED_SIM_TYPE]: setSelectedSimType,
  [types.REHYDRATE_SIM_TYPE_DETAILS]: rehydrateSimTypeDetails
});

export default simTypeDetailsReducer;
