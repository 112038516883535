import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';
import { executeApiRequest } from '../helpers/api';

const getOneTimeChargeLoading = loading => ({
  type: types.GET_ONE_TIME_CHARGE_LOADING,
  payload: loading
});

const getOneTimeChargeSuccess = res => ({
  type: types.GET_ONE_TIME_CHARGE_SUCCESS,
  payload: res
});

const getOneTimeChargeFailure = error => ({
  type: types.GET_ONE_TIME_CHARGE_FAILURE,
  payload: error
});

export const setFinalPrice = price => ({
  type: types.SET_FINAL_PRICE,
  payload: price
});

export const getOneTimeCharge = (challengeCode, mobileNumber, simType) => {
  const url = api.oneTimeCharges;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      challengeCode: challengeCode,
      mobileNumber: mobileNumber
    },
    body: JSON.stringify({
      simType
    })
  };

  return (dispatch, getState) => {
    dispatch(getOneTimeChargeLoading(true));
    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return response.json();
        } else {
          throw new Error({ message: response.statusText });
        }
      })
      .then(response => {
        if (response) {
          dispatch(getOneTimeChargeSuccess(response));
        } else {
          dispatch(
            getOneTimeChargeFailure({
              message: 'Failed to get one time charge'
            })
          );
        }
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('GET_ONE_TIME_CHARGE', false));
        dispatch(getOneTimeChargeFailure(error));
      });
  };
};
