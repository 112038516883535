// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-details-js": () => import("./../../../src/pages/confirm-details.js" /* webpackChunkName: "component---src-pages-confirm-details-js" */),
  "component---src-pages-delivery-details-js": () => import("./../../../src/pages/delivery-details.js" /* webpackChunkName: "component---src-pages-delivery-details-js" */),
  "component---src-pages-failure-js": () => import("./../../../src/pages/failure.js" /* webpackChunkName: "component---src-pages-failure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-select-number-js": () => import("./../../../src/pages/select-number.js" /* webpackChunkName: "component---src-pages-select-number-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-unable-retrieve-js": () => import("./../../../src/pages/unable-retrieve.js" /* webpackChunkName: "component---src-pages-unable-retrieve-js" */),
  "component---src-pages-user-details-js": () => import("./../../../src/pages/user-details.js" /* webpackChunkName: "component---src-pages-user-details-js" */)
}

