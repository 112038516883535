import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  token: ''
};

export const setAuthToken = (state, action) => {
  return {
    ...state,
    token: action.payload
  };
};

const authReducer = createReducer(initialState, {
  [types.SET_AUTH_TOKEN]: setAuthToken
});

export default authReducer;
