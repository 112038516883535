import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  token: ''
};

export const getApigeeTokenSuccess = (state, action) => {
  return { ...state, token: action.payload };
};

export const rehydrateApigeeToken = (state, action) => {
  return action.payload;
};

const apigeeTokenReducer = createReducer(initialState, {
  [types.GET_APIGEE_TOKEN_SUCCESS]: getApigeeTokenSuccess,
  [types.REHYDRATE_APIGEE_TOKEN]: rehydrateApigeeToken
});

export default apigeeTokenReducer;
