import { api } from '../constants/app_configuration';
import types from './types';
import { setLoading } from './loading';
import { getYear } from 'date-fns';

import { PROMO_CODE_RESPONSE_CODES } from '../constants/response_types.json';
import { CONTRACT_TYPES } from '../constants/transaction_codes.json';
import { PRODUCT_CODE } from '../constants/app_configuration';
import { isEmptyObj } from '../helpers/validation';
import nationalities from '../constants/nationalities.json';
import { ID_TYPE_OPTIONS } from '../constants/identification_types.json';
import { capitalize, maskString } from '../helpers/formatter';
import { STORAGE_TYPE, removeState, setState } from '../helpers/persist-state';
import { executeApiRequest } from '../helpers/api';
import { isEsim } from '../helpers/userDetailsForm';

const submitOrderLoading = loading => ({
  type: types.SUBMIT_ORDER_LOADING,
  payload: {
    loading
  }
});

const submitOrderFailure = error => ({
  type: types.SUBMIT_ORDER_FAILURE,
  payload: {
    message: error.message
  }
});

const getAddressObject = (addrObj, selfCollection = false) => {
  const {
    blk,
    blockNumber,
    buildClass,
    street,
    floorNumber,
    unitNumber,
    postalCode
  } = addrObj.address;

  return {
    blockType: blk,
    blockNo: blockNumber,
    buildClass: buildClass,
    street: street,
    unitNo: floorNumber && unitNumber ? `#${floorNumber}-${unitNumber}` : '',
    postCode: postalCode
  };
};

export const splitFullName = fullName => {
  if (!fullName.length) {
    return {
      firstName: null,
      lastName: null
    };
  }
  const firstWhiteSpace = fullName.indexOf(' ');
  if (firstWhiteSpace === -1) {
    return {
      firstName: fullName,
      lastName: fullName
    };
  }
  return {
    firstName: fullName.substring(firstWhiteSpace + 1),
    lastName: fullName.substring(0, firstWhiteSpace)
  };
};

export const submitOrder = data => {
  const url = api.submitOrder;

  const {
    userDetails,
    deliveryDetails,
    selectedNumber,
    selectedSimType,
    authToken,
    selectedPopstation = {},
    promoCodeDetails = {},
    queryString = {
      partner: '',
      partner_parameter: ''
    },
    barringType = []
  } = data;

  const deliveryAddress = getAddressObject(deliveryDetails);

  let customerAddress = {},
    selfCollection = {},
    promoCode = {};
  customerAddress = getAddressObject(userDetails);

  if (!isEmptyObj(selectedPopstation)) {
    selfCollection = {
      outletCode: selectedPopstation.KioskId,
      popStationName: selectedPopstation.POPStationName
    };
  }

  if (promoCodeDetails !== null) {
    promoCode = {
      intVoucherCode: promoCodeDetails.productCode,
      voucherCode: promoCodeDetails.promoCode
    };
  }

  let conType = '';
  switch (selectedNumber.mode) {
    case 'portIn':
      conType = CONTRACT_TYPES.PORT_IN;
      break;
    case 'reserveNumber':
      conType = CONTRACT_TYPES.RESERVE_NUMBER;
      break;
    default:
      console.log('Error: Invalid contract type');
  }

  const isEsimSelected = isEsim(selectedSimType);

  const dataToSubmit = {
    hybridSimOrder: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      barringType,
      customerId: userDetails.idNo,
      custIdType: userDetails.idType,
      dob: userDetails.dateOfBirth,
      gender: userDetails.gender,
      email: userDetails.email,
      contactNumber: userDetails.contactNo,
      serviceNumber: selectedNumber.number,
      price: userDetails.price,
      oneTimeChargeKey: userDetails.oneTimeChargeKey,
      lockedToken: selectedNumber.lockedToken,
      deliveryDate: deliveryDetails.date,
      deliveryCode: deliveryDetails.code,
      deliveryTime: deliveryDetails.time,
      deliverySlotType: deliveryDetails.slotType,
      delPMode: isEsimSelected ? 'NA' : deliveryDetails.pMode,
      deliveryAddress: deliveryAddress,
      customerAddress: customerAddress,
      conType: conType,
      frontIdPath: userDetails.frontIdImage.name,
      backIdPath: userDetails.backIdImage.name,
      challengeCode: authToken,
      productCode: PRODUCT_CODE,
      ...selfCollection,
      ...promoCode,
      partnerIntegration: {
        partner: queryString.partner,
        partnerParameter: queryString.partner_parameter
      }
    },
    simType: selectedSimType,
    failureUrl: `${process.env.GATSBY_HYBRID_DOMAIN}/failure/`,
    successUrl: `${process.env.GATSBY_HYBRID_DOMAIN}/success/`
  };
  // ekyc register
  if (userDetails.referenceNo) {
    const { firstName, lastName } = splitFullName(userDetails.firstName);
    dataToSubmit.hybridSimOrder.firstName = capitalize(firstName);
    dataToSubmit.hybridSimOrder.lastName = capitalize(lastName);
    dataToSubmit.myInfoDetail = {
      referenceNumber: userDetails.referenceNo,
      uinfin: userDetails.idNo,
      dob: userDetails.originDateOfBirth,
      name: userDetails.firstName,
      passType: userDetails.passType,
      passStatus: userDetails.passStatus,
      idExpiry: userDetails.passExpiryDate
    };
  }
  // add up qualtric variables for survey
  let gomo_buyflow_age =
    new Date().getFullYear() - getYear(new Date(userDetails.dateOfBirth));
  const nationality = nationalities.find(
    n => n.value === userDetails.nationality
  );
  const idTypeOptions = ID_TYPE_OPTIONS.find(
    n => n.value === userDetails.idType
  );
  let qualtricVariables = {
    gomo_buyflow_serviceNumber: `65${selectedNumber.number}`,
    gomo_buyflow_age,
    gomo_buyflow_dob: userDetails.dateOfBirth,
    gomo_buyflow_idType: idTypeOptions.text,
    gomo_buyflow_nationality: nationality.text,
    gomo_buyflow_productDetail: PRODUCT_CODE,
    gomo_buyflow_promocode:
      Object.keys(promoCode).length !== 0 ? promoCode.voucherCode : '',
    gomo_buyflow_conType:
      conType === CONTRACT_TYPES.RESERVE_NUMBER
        ? 'Sign up with new number'
        : 'portIn'
  };
  localStorage.setItem('qualtricVariables', JSON.stringify(qualtricVariables));

  return (dispatch, getState) => {
    if (
      !isEsimSelected &&
      (!deliveryDetails.date || !deliveryDetails.code || !deliveryDetails.time)
    ) {
      return;
    }
    dispatch(submitOrderLoading(true));
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataToSubmit)
    };

    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        const { status } = response;

        const code =
          PROMO_CODE_RESPONSE_CODES[status] === undefined ? 'default' : status;
        if (status === 200) {
          return response;
        } else {
          throw new Error(code);
        }
      })
      .then(response => {
        //Clear localstorage data
        if (getState('queryString')) {
          removeState('queryString');
        }
        //Remove cust data
        removeState('customerData');

        // Set order details in session storage
        setState(
          'oDetails',
          {
            simType: selectedSimType,
            promoCode: promoCodeDetails?.promoCode,
            networkType: selectedNumber.mode,
            email: maskString({
              pattern: 'email',
              text: userDetails.email
            }),
            isSelfCollection: !isEmptyObj(selectedPopstation)
          },
          STORAGE_TYPE.SESSION_STORAGE
        );

        window.onbeforeunload = null;
        window.location.href = response.headers.get('Location');
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('SUBMIT_ORDER', false));
        dispatch(submitOrderFailure(error));
      });
  };
};
