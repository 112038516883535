import types from '../actions/types';
import { createReducer } from './index';

const initialState = {
  details: null,
  finalPrice: 0,
  loading: false
};

export const getOneTimeChargeSuccess = (state, action) => {
  return {
    ...state,
    details: action.payload,
    loading: false
  };
};

export default createReducer(initialState, {
  [types.GET_ONE_TIME_CHARGE_SUCCESS]: getOneTimeChargeSuccess
});
