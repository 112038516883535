import { ROUTES } from '../constants/routes';
import { isEsim } from './userDetailsForm';

/**
 * Determine the path to redirect from the 'Select Number' page based on the selected SIM type.
 * @param {string} selectedSimType - Selected sim type
 */
export const getRedirectPathFromSelectNumberPage = selectedSimType => {
  return isEsim(selectedSimType)
    ? ROUTES.USER_DETAILS
    : ROUTES.DELIVERY_DETAILS;
};
