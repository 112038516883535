import { PRODUCTION } from '../constants/environment_names.json';
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  compose
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer } from '../reducers';

let middleware;
if (process.env.NODE_ENV !== PRODUCTION) {
  middleware = compose(applyMiddleware(thunk, createLogger()));
} else {
  middleware = applyMiddleware(thunk);
}

const createStore = () => reduxCreateStore(reducer, {}, middleware);

export default createStore;
