import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  firstName: '',
  lastName: '',
  title: '',
  gender: '',
  email: '',
  confirmEmail: '',
  contactNo: '',
  dateOfBirth: '',
  originDateOfBirth: '',
  idNo: '',
  idType: '',
  nationality: '',
  frontIdImage: '',
  backIdImage: '',
  address: {
    blk: '',
    blockNumber: '',
    street: '',
    postalCode: '',
    floorNumber: '',
    unitNumber: '',
    noUnitDetails: false
  },
  myinfoMode: '',
  hasSubmittedOnce: false,
  subscriptionError: false,
  genericError: false,
  myinfoError: false,
  pendingPortInError: false,
  numberNotFoundError: false,
  blacklistedCustomerError: false,
  referenceNo: null,
  passType: null,
  passStatus: null,
  passExpiryDate: null,
  price: '',
  oneTimeChargeKey: ''
};

export const setSubscriptionError = (state, action) => {
  return {
    ...state,
    subscriptionError: action.payload
  };
};

export const setPendingPortInError = (state, action) => {
  return {
    ...state,
    pendingPortInError: action.payload
  };
};

export const setNumberNotFoundError = (state, action) => {
  return {
    ...state,
    numberNotFoundError: action.payload
  };
};

export const setBlacklistedCustomerError = (state, action) => {
  return {
    ...state,
    blacklistedCustomerError: action.payload
  };
};

export const setGenericError = (state, action) => {
  return {
    ...state,
    genericError: action.payload
  };
};

export const setMyinfoError = (state, action) => {
  return {
    ...state,
    myinfoError: action.payload
  };
};
export const setMyinfoMode = (state, action) => {
  return {
    ...state,
    myinfoMode: action.payload
  };
};

export const setUserDetails = (_, action) => {
  return { ...action.payload, hasSubmittedOnce: true };
};

export const resetUserDetails = () => {
  return initialState;
};

export default createReducer(initialState, {
  [types.SET_USER_DETAILS]: setUserDetails,
  [types.SET_SUBSCRIPTION_ERROR]: setSubscriptionError,
  [types.SET_PENDING_PORTIN_ERROR]: setPendingPortInError,
  [types.SET_NUMBER_NOT_FOUND_ERROR]: setNumberNotFoundError,
  [types.SET_BLACKLISTED_CUSTOMER_ERROR]: setBlacklistedCustomerError,
  [types.SET_GENERIC_ERROR]: setGenericError,
  [types.SET_MYINFO_ERROR]: setMyinfoError,
  [types.RESET_USER_DETAILS]: resetUserDetails,
  [types.SET_MYINFO_MODE]: setMyinfoMode
});
