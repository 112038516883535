import { FAILURE } from '../constants/response_types.json';
import ACTION_TYPES from '../actions/types';

export default (state = {}, action) => {
  // eslint-disable-line
  const { type, payload } = action;
  const matches = /(.*)_(LOADING|FAILURE)/.exec(type);

  if (type === ACTION_TYPES.CLEAR_ERROR) {
    return {
      ...state,
      [payload]: ''
    };
  }

  // not a *_LOADING  / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_NUMBERS_FAILURE
    //      else clear errorMessage when receiving GET_NUMBERS_LOADING
    [requestName]: requestState === FAILURE ? payload.message : ''
  };
};
