export const sanitizeString = str => {
  return str.replace(/[^A-Za-z0-9 ]/g, '');
};

export const capitalize = s =>
  s
    .toLowerCase()
    .split(' ')
    .map(w => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');

export const maskString = ({ mask = '*', pattern, text }) => {
  switch (pattern) {
    case 'email': {
      if (!text.includes('@') || text.length === 0) {
        return text;
      }

      const emailArray = text.split('@');
      const username = emailArray[0];
      const domain = emailArray[1] || '';
      const firstLetter = username[0] || '';
      const lastLetter =
        username.length > 1 ? username[username.length - 1] : '';
      const maskString = mask.repeat(3);

      return `${firstLetter}${maskString}${lastLetter}@${domain}`;
    }
    default:
      return text;
  }
};

export const replaceSpaces = (text, symbol = '-', lowercase = true) => {
  const replacedText = (text || '').replace(/ /g, symbol);
  return lowercase ? replacedText.toLowerCase() : replacedText;
};
