import { combineReducers } from 'redux';
import loadingReducer from './loading';
import errorReducer from './error';
import availableNumbersReducer from './availableNumbers';
import selectedNumberReducer from './selectedNumber';
import deliveryDatesReducer from './deliveryDates';
import retrievedAddressReducer from './retrievedAddress';
import deliveryDetailsReducer from './deliveryDetails';
import userDetailsReducer from './userDetails';
import uploadedImagesReducer from './uploadedImages';
import authReducer from './auth';
import queryStringReducer from './handleQueryString';
import selfCollection from './selfcollection';
import promoCode from './promoCode';
import oneTimeCharge from './oneTimeCharge';
import apigeeTokenReducer from './apigeeToken';
import simTypeDetailsReducer from './simTypeDetails';

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

export const reducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  availableNumbers: availableNumbersReducer,
  simTypeDetails: simTypeDetailsReducer,
  selectedNumber: selectedNumberReducer,
  deliveryDates: deliveryDatesReducer,
  retrievedAddress: retrievedAddressReducer,
  deliveryDetails: deliveryDetailsReducer,
  userDetails: userDetailsReducer,
  uploadedImages: uploadedImagesReducer,
  auth: authReducer,
  apigeeToken: apigeeTokenReducer,
  handleQueryString: queryStringReducer,
  selfCollection: selfCollection,
  promoCode: promoCode,
  oneTimeCharge: oneTimeCharge
});
