import { api } from '../constants/app_configuration';
import { retrieveAddressLoading } from './retrieveAddress';
import {
  setMyinfoError,
  resetUserDetails,
  setUserDetails
} from './setUserDetails';
import { resetUploadImage } from './uploadImage';
import { retrieveAddressClear } from './retrieveAddress';
import { setLoading } from './loading';
import { navigate } from 'gatsby';
import {
  ID_TYPE_OPTIONS,
  CITIZEN_PREFIX
} from '../constants/identification_types.json';
import { isEmptyString } from '../helpers/validation';
import { executeApiRequest } from '../helpers/api';

const myinfoRequestHeader = {
  Username: 'gomoBuyFlow',
  TenantId: '003',
  Secret: process.env.GATSBY_EKYC_SECRET
};

export const useMyinfo = customerData => {
  const authUrl = api.authMyInfo;
  const challengeCode = customerData.auth.token;
  const mobileNumber = customerData.selectedNumber.number;
  const authOptions = {
    method: 'POST',
    headers: {
      challengeCode,
      mobileNumber
    }
  };

  /*
    This header will be injected only for Dev environment via bamboo variable.
    For Prod & Non Prod this will be injected at Imperva. 
  */
  const authorizationHeader = process.env.GATSBY_BASIC_OAUTH || '';

  if (authorizationHeader) {
    authOptions.headers['x-Authorization'] = `Basic ${authorizationHeader}`;
  }

  return (dispatch, getState) => {
    dispatch(retrieveAddressLoading(true));
    return executeApiRequest(authUrl, getState().apigeeToken.token, authOptions)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(response => {
        //get response to create access token for get myinfo url
        const oauthToken = response.access_token;
        customerData.selectedNumber.oauthToken = oauthToken;
        const url = api.myInfoUrl;
        const options = {
          method: 'POST',
          headers: {
            'x-Authorization': `Bearer ${oauthToken}`,
            'Content-Type': 'application/json',
            challengeCode,
            mobileNumber
          },
          body: JSON.stringify({
            redirectURL: process.env.GATSBY_GOMO_END_POINT + '/user-details',
            data:
              'uinfin,name,dob,nationality,passtype,passstatus,passexpirydate,mobileno,email,regadd'
          })
        };
        executeApiRequest(url, getState().apigeeToken.token, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              throw response;
            }
          })
          .then(response => {
            // hit url then redirect to myinfo page & save auth code, number into local storage if success
            dispatch(setLoading('RETRIEVE_ADDRESS', false));
            localStorage.setItem('customerData', JSON.stringify(customerData));
            window.onbeforeunload = null;
            window.location.href = response.url;
          })
          .catch(() => {
            dispatch(setLoading('RETRIEVE_ADDRESS', false));
            dispatch(setMyinfoError(true));
          });
      })
      .catch(() => {
        dispatch(setLoading('RETRIEVE_ADDRESS', false));
        dispatch(setMyinfoError(true));
      });
  };
};

const getGomoPassType = code => {
  if (['P1Pass', 'P2Pass', 'QPass', 'PEP', 'TEP', 'OVE'].includes(code)) {
    return '05';
  } else if (code === 'RPass') {
    return '09';
  } else if (code === 'SPass') {
    return '06';
  } else if (code === 'DP') {
    return '04';
  } else if (code === '') {
    return '01';
  } else if (code === 'WHP') {
    return '13';
  } else if (code === 'Entre') {
    return '14';
  } else if (code === 'LTVP') {
    return '15';
  } else if (code === 'LOC') {
    return '16';
  } else if (code === 'MWP') {
    return '17';
  } else if (code === 'STP') {
    return '08';
  } else if (code === 'LTVP+') {
    return '19';
  } else {
    return null;
  }
};

export const isCitizenType = (code, idNo) => {
  if (isEmptyString(idNo)) return false;

  const citizenPassType =
    getGomoPassType(code) ===
    ID_TYPE_OPTIONS.find(id => id.text === 'NRIC').value;

  const citizenPrefix = CITIZEN_PREFIX;
  return (
    citizenPassType && citizenPrefix.includes(idNo.charAt(0).toLowerCase())
  );
};

export const getMyinfoData = ({
  code,
  oauthToken,
  challengeCode,
  mobileNumber
}) => {
  const url = api.customerInfo;
  const options = {
    method: 'POST',
    headers: {
      'x-Authorization': `Bearer ${oauthToken}`,
      'Content-Type': 'application/json',
      challengeCode,
      mobileNumber
    },
    body: JSON.stringify({
      redirectURL: process.env.GATSBY_GOMO_END_POINT + '/user-details',
      data: code
    })
  };

  return (dispatch, getState) => {
    dispatch(retrieveAddressLoading(true));
    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(response => {
        const userDetails = {
          firstName: response?.name?.value || '',
          backIdImage: '',
          frontIdImage: '',
          contactNo: response?.mobileno?.nbr.value || '',
          dateOfBirth: response?.dob?.value || '',
          originDateOfBirth: response?.dob?.value || '',
          email: response?.email?.value || '',
          confirmEmail: '',
          gender: '',
          idNo: response?.uinfin?.value || '',
          idType:
            isEmptyString(response?.passtype?.code) &&
            !isCitizenType(response?.passtype?.code, response?.uinfin?.value)
              ? ''
              : getGomoPassType(response?.passtype?.code),
          nationality: response?.nationality?.code,
          title: '',
          /** Temporary only - disregard until address spec is confirmed **/
          address: {
            blk: '',
            blockNumber: '',
            buildClass: '',
            floorNumber: '',
            postalCode: '',
            street: '',
            unitNumber: ''
          },
          referenceNo: response?.referenceNo || '',
          passStatus: response?.passstatus?.value || '',
          passType: response?.passtype || '',
          passExpiryDate: response?.passexpirydate?.value || '',
          myinfoMode: isCitizenType(
            response?.passtype?.code,
            response?.uinfin?.value
          )
            ? 'citizen'
            : 'workPass'
        };
        // map data for userDetail fields and declare mode for myInfo
        dispatch(setUserDetails(userDetails));
        // remove local storage after rechieve success
        // localStorage.removeItem('customerData');
        dispatch(setLoading('RETRIEVE_ADDRESS', false));
      })
      .catch(() => {
        dispatch(setLoading('RETRIEVE_ADDRESS', false));
        dispatch(resetUserDetails());
        dispatch(resetUploadImage());
        dispatch(retrieveAddressClear());
        navigate('/unable-retrieve/');
      });
  };
};
