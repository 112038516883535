import { navigate } from 'gatsby';
import types from './types';
import { api } from '../constants/app_configuration';
import { apiFailure } from './error';
import { executeApiRequest } from '../helpers/api';

export const setUserDetails = userDetails => ({
  type: types.SET_USER_DETAILS,
  payload: userDetails
});

export const setSubscriptionError = error => ({
  type: types.SET_SUBSCRIPTION_ERROR,
  payload: error
});

export const setPendingPortInError = error => ({
  type: types.SET_PENDING_PORTIN_ERROR,
  payload: error
});

export const setNumberNotFoundError = error => ({
  type: types.SET_NUMBER_NOT_FOUND_ERROR,
  payload: error
});

export const setBlacklistedCustomerError = error => ({
  type: types.SET_BLACKLISTED_CUSTOMER_ERROR,
  payload: error
});

export const setGenericError = error => ({
  type: types.SET_GENERIC_ERROR,
  payload: error
});

export const setMyinfoError = error => ({
  type: types.SET_MYINFO_ERROR,
  payload: error
});

export const setMyinfoMode = mode => ({
  type: types.SET_MYINFO_MODE,
  payload: mode
});

export const resetUserDetails = () => ({
  type: types.RESET_USER_DETAILS
});

export const checkSubscriptionInfo = (userDetails, serviceNumber, authCode) => {
  const url = api.checkSubscriptionInfo;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      subscriptionInfoReq: {
        appName: 'appgomo',
        serviceNumber: serviceNumber,
        challengeCode: authCode,
        custId: userDetails.idNo
      }
    })
  };

  return (dispatch, getState) => {
    //set subscription error to false before fetch call
    dispatch(setSubscriptionError(false));

    //Check subscription info for customer id mismatch
    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(response => {
        if (
          response.subscriptionInfoResp &&
          response.subscriptionInfoResp.status === '103'
        ) {
          dispatch(setSubscriptionError(true));
        } else if (
          response.subscriptionInfoResp &&
          response.subscriptionInfoResp.status === '105'
        ) {
          dispatch(setPendingPortInError(true));
        } else if (
          response.subscriptionInfoResp &&
          response.subscriptionInfoResp.status === '106'
        ) {
          dispatch(setNumberNotFoundError(true));
        } else if (
          response.subscriptionInfoResp &&
          response.subscriptionInfoResp.status === '107'
        ) {
          dispatch(setBlacklistedCustomerError(true));
        } else if (
          response.subscriptionInfoResp &&
          (response.subscriptionInfoResp.status === '994' ||
            response.subscriptionInfoResp.status === '995')
        ) {
          dispatch(setGenericError(true));
        } else {
          dispatch(setUserDetails(userDetails));
          navigate('/confirm-details/');
        }
      })
      .catch(() => {
        // Check for thrown errors
        dispatch(
          apiFailure({
            message: 'Backend Error'
          })
        );
      });
  };
};
