import { isValidAlphaWithSpace, isValidEmail } from '@lux/helpers';
import {
  isUserDataRetrievedThroughMyInfo,
  shouldShowLtvpDigitalPassSection,
  shouldShowPhysicalCardUploadSection
} from './userDetailsForm';

export const checkName = (name, errorField) => {
  let error = '';
  if (!name || name.trim() === '') {
    error = `Please enter your ${errorField}.`;
  } else if (!isValidAlphaWithSpace(name)) {
    error = 'Please enter a valid name.';
  }
  return error;
};

export const checkEmail = email => {
  let error = '';
  if (!email || email.trim() === '') {
    error = 'Please enter your email.';
  } else if (!isValidEmail(email)) {
    error = 'Please enter a valid email.';
  }
  return error;
};

export const checkImages = ({ images, myinfoMode, idType, ltvpCardType }) => {
  const showPhsyicalCardUploadSection = shouldShowPhysicalCardUploadSection({
    myinfoMode,
    idType,
    ltvpCardType
  });
  if (!showPhsyicalCardUploadSection) {
    return '';
  }

  let error = '';

  if (!images) {
    error = 'Please upload all required images.';
  } else {
    Object.keys(images).forEach(key => {
      if (!images[key].imageId || !images[key].previewImgSrc) {
        error = 'Please upload all required images.';
      }
    });
  }

  return error;
};

export const checkLtvpDigitalPass = ({ myinfoMode, idType, ltvpCardType }) => {
  const showLtvpDigitalPassSection = shouldShowLtvpDigitalPassSection({
    myinfoMode,
    idType,
    ltvpCardType
  });
  if (!showLtvpDigitalPassSection) {
    return '';
  }

  return !isUserDataRetrievedThroughMyInfo(myinfoMode)
    ? 'Please retrieve your details via Myinfo with Singpass'
    : '';
};

export const checkIsEmpty = (text, keyToDisplay) => {
  let error = '';
  if (!text || text.trim() === '') {
    error = `Please select your ${keyToDisplay}.`;
  }
  return error;
};

export const isEmptyObj = obj => {
  return Object.keys(obj).length === 0;
};

export const checkFieldsInObject = (stateOrPropsObj, fieldsToCheck) => {
  let isValid = true;
  fieldsToCheck.forEach(field => {
    if (!stateOrPropsObj[field]) isValid = false;
  });
  return isValid;
};

export const isEmptyString = str => {
  return str === '' || str === null || str === 'null';
};

export const isMID = id => {
  return id.toLowerCase() === 'm';
};
