import React from 'react';
import { Provider } from 'react-redux';
import { AnalyticsListener } from '@lux/components-gomo';

import createStore from './src/state/createStore';
import actions from './src/actions';

const store = createStore();

const WrapWithProvider = ({ element }) => {
  const pushEvent = (_, payload = {}) => {
    store.dispatch(actions.pushEventToDataLayer(payload));
  };

  return (
    <Provider store={store}>
      <AnalyticsListener onEvent={pushEvent}>{element}</AnalyticsListener>
    </Provider>
  );
};

export default WrapWithProvider;
