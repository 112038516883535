import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  mode: '',
  number: '',
  portInNumberAcknowledged: false,
  lockedToken: '',
  nwType: '',
  validationFailed: false,
  callBarringType: ''
};

export const setSelectedNumber = (state, action) => {
  return {
    mode: action.payload.mode,
    number: action.payload.number,
    portInNumberAcknowledged: !!action.payload.portInNumberAcknowledged,
    lockedToken: action.payload.lockedToken,
    nwType: action.payload.nwType,
    callBarringType: state.callBarringType
  };
};

export const setNumberValidationStatus = (state, action) => {
  return {
    ...state,
    validationFailed: action.payload
  };
};

export const rehydrateSelectedNumber = (_, action) => {
  return action.payload;
};

export const setCallBarringType = (state, action) => {
  return {
    ...state,
    callBarringType: action.payload
  };
};

export default createReducer(initialState, {
  [types.PORT_IN_NUMBER_SUCCESS]: setSelectedNumber,
  [types.RESERVE_NUMBER_SUCCESS]: setSelectedNumber,
  [types.VALIDATE_NUMBER]: setNumberValidationStatus,
  [types.REHYDRATE_NUMBER]: rehydrateSelectedNumber,
  [types.SET_CALL_BARRING_TYPE]: setCallBarringType
});
