import types from '../actions/types';
import { createReducer } from './index';

export const initialState = {
  data: null,
  deliveryAddressList: null,
  billingAddressList: null,
  addressStatus: null
};

export const retrieveAddressClear = () => {
  return initialState;
};

export const retrieveDeliveryAddressClear = state => {
  return {
    ...state,
    deliveryAddressList: null
  };
};

export const retrieveBillingAddressClear = state => {
  return {
    ...state,
    billingAddressList: null
  };
};

export const retrieveDeliveryAddressSuccess = (state, action) => {
  return {
    ...state,
    deliveryAddressList: action.payload,
    loading: false
  };
};

export const retrieveBillingAddressSuccess = (state, action) => {
  return {
    ...state,
    billingAddressList: action.payload,
    loading: false
  };
};

export const retrieveAddressSuccess = state => {
  return {
    ...state,
    loading: false
  };
};

export const retrieveAddressStatus = (state, action) => {
  return {
    ...state,
    addressStatus: action.payload
  };
};

export const rehydrateRetrieveAddress = (state, action) => {
  return action.payload;
};

export default createReducer(initialState, {
  [types.RETRIEVE_ADDRESS_SUCCESS]: retrieveAddressSuccess,
  [types.RETRIEVE_ADDRESS_STATUS]: retrieveAddressStatus,
  [types.RETRIEVE_DELIVERY_ADDRESS_SUCCESS]: retrieveDeliveryAddressSuccess,
  [types.RETRIEVE_BILLING_ADDRESS_SUCCESS]: retrieveBillingAddressSuccess,
  [types.RETRIEVE_ADDRESS_CLEAR]: retrieveAddressClear,
  [types.RETRIEVE_DELIVERY_ADDRESS_CLEAR]: retrieveDeliveryAddressClear,
  [types.RETRIEVE_BILLING_ADDRESS_CLEAR]: retrieveBillingAddressClear,
  [types.REHYDRATE_RETRIEVE_ADDRESS]: rehydrateRetrieveAddress
});
