import types from '../actions/types';
import { createReducer } from './index';

const initialState = {
  details: null,
  loading: false
};

const retrievePromoCodeClear = () => {
  return initialState;
};

const retrievePromoCodeSuccess = (state, action) => {
  return {
    ...state,
    details: action.payload,
    loading: false
  };
};

export default createReducer(initialState, {
  [types.RETRIEVE_PROMO_CODE_SUCCESS]: retrievePromoCodeSuccess,
  [types.RETRIEVE_PROMO_CODE_CLEAR]: retrievePromoCodeClear
});
