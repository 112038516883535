import {
  GTM_DATALAYER_VARIABLE_NETWORK_LINE_TYPE,
  GTM_DATALAYER_VARIABLE_SIM_TYPE,
  GTM_EVENT_ACTIONS,
  GTM_EVENT_CATEGORIES,
  GTM_EVENT_COMPONENT_TYPE,
  GTM_EVENT_LABELS
} from '../constants/gtmDataLayerEvents';
import { SIM_TYPES } from '../constants/sim_types.json';

export const dataLayerPush = payload => {
  if (window.dataLayer) {
    window.dataLayer.push(function() {
      this.reset();
    });
    window.dataLayer.push(payload);
  }
};

export const getDataLayerVariableNetworkType = networkType => {
  if (networkType === 'portIn') {
    return GTM_DATALAYER_VARIABLE_NETWORK_LINE_TYPE.PORT_IN;
  }
  return GTM_DATALAYER_VARIABLE_NETWORK_LINE_TYPE.RESERVE_NUMBER;
};

export const getDatalayerVariableSimTypeValue = simType => {
  if (simType === SIM_TYPES.ESIM) {
    return GTM_DATALAYER_VARIABLE_SIM_TYPE.ESIM;
  } else if (simType === SIM_TYPES.PSIM) {
    return GTM_DATALAYER_VARIABLE_SIM_TYPE.PSIM;
  }
  return '';
};

export const getErrorPopupDataLayerVars = (eventData = {}) => {
  return {
    render: {
      event: eventData.event,
      eventCategory: GTM_EVENT_CATEGORIES.POPUP,
      eventAction: GTM_EVENT_ACTIONS.POPUP,
      eventLabel: GTM_EVENT_LABELS.POPUP,
      componentType: GTM_EVENT_COMPONENT_TYPE.POPUP,
      errorName: eventData.errorName
    },
    buttonClick: {
      event: eventData.event,
      eventCategory: GTM_EVENT_CATEGORIES.CONTENT_CLICK,
      eventAction: GTM_EVENT_ACTIONS.BUTTON_CLICK,
      componentType: GTM_EVENT_COMPONENT_TYPE.POPUP,
      eventLabel: eventData.eventLabel,
      errorName: eventData.errorName
    }
  };
};
