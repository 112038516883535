import NProgress from 'nprogress';
import { navigate } from 'gatsby';
import { api } from '../constants/app_configuration';
import types from './types';
import { apiFailure } from './error';
import { setLoading } from './loading';

import { setAuthToken } from './auth';
import { executeApiRequest } from '../helpers/api';
import { getRedirectPathFromSelectNumberPage } from '../helpers/routes';

const reserveNumberLoading = loading => ({
  type: types.RESERVE_NUMBER_LOADING,
  payload: loading
});

const reserveNumberSuccess = data => ({
  type: types.RESERVE_NUMBER_SUCCESS,
  payload: {
    mode: 'reserveNumber',
    number: data.number,
    lockedToken: data.lockedToken
  }
});

const reserveNumberFailure = error => ({
  type: types.RESERVE_NUMBER_FAILURE,
  payload: error
});

const addUnavailableNumber = number => ({
  type: types.ADD_UNAVAILABLE_NUMBER,
  payload: number
});

export const reserveNumber = (number, recaptchaResponse) => {
  const url = api.reserveNumber;

  const options = {
    method: 'POST',
    headers: {
      'g-recaptcha-response': recaptchaResponse,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ number: number })
  };

  return (dispatch, getState) => {
    dispatch(reserveNumberLoading(true));
    NProgress.start();

    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        const { status, statusText } = response;
        if (status !== 200) {
          const error = { message: statusText || 'Fail to reserve number' };
          dispatch(addUnavailableNumber(number));
          dispatch(reserveNumberFailure(error));
          return null;
        } else {
          return response.json();
        }
      })
      .then(response => {
        if (response) {
          const data = {
            number: response.number,
            lockedToken: response.lockedToken
          };
          // If valid
          dispatch(reserveNumberSuccess(data));
          dispatch(setAuthToken(response.authCode));

          const selectedSimType = getState().simTypeDetails.selectedSimType;
          navigate(getRedirectPathFromSelectNumberPage(selectedSimType));
        }
        NProgress.done();
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('RESERVE_NUMBER', false));
        dispatch(apiFailure(error));
        NProgress.done();
      });
  };
};

export const unreserveNumber = (lockedToken, number, recaptchaResponse) => {
  const url = api.unreserveNumber;

  const options = {
    method: 'POST',
    headers: {
      'g-recaptcha-response': recaptchaResponse,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ number, lockedToken })
  };

  return (dispatch, getState) => {
    return executeApiRequest(url, getState().apigeeToken.token, options);
  };
};

export const rereserveNumber = (data, recaptchaResponse) => {
  const url = api.rereserveNumber;

  const payload = {
    reserveRequest: {
      number: data.selectedNumber
    },
    unreserveRequest: {
      number: data.reservedNumber,
      lockedToken: data.lockedToken
    }
  };

  const options = {
    method: 'POST',
    headers: {
      'g-recaptcha-response': recaptchaResponse,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };

  return (dispatch, getState) => {
    dispatch(reserveNumberLoading(true));
    NProgress.start();

    return executeApiRequest(url, getState().apigeeToken.token, options)
      .then(response => {
        const { status, statusText } = response;
        if (status !== 200) {
          const error = { message: statusText || 'Fail to rereserve number' };
          dispatch(addUnavailableNumber(data.selectedNumber));
          dispatch(reserveNumberFailure(error));
          return null;
        } else {
          return response.json();
        }
      })
      .then(response => {
        if (response) {
          const data = {
            number: response.reserveResponse.number,
            lockedToken: response.reserveResponse.lockedToken
          };
          // If valid
          dispatch(reserveNumberSuccess(data));
          dispatch(setAuthToken(response.reserveResponse.authCode));

          const selectedSimType = getState().simTypeDetails.selectedSimType;
          navigate(getRedirectPathFromSelectNumberPage(selectedSimType));
        }
        NProgress.done();
      })
      .catch(error => {
        // Check for thrown errors
        dispatch(setLoading('RESERVE_NUMBER', false));
        dispatch(apiFailure(error));
        NProgress.done();
      });
  };
};
