import qs from 'query-string';
import sanitiser from 'sanitize-html';
import types from '../actions/types';
import { createReducer } from './index';
import { setState } from '../helpers/persist-state';

const sanitiserConfig = {
  allowedTags: [], // disallow all HTML or JS tags
  allowedAttributes: {} // disallow all attributes
};

const validExpressions = {
  partner: /^shopback$/g,
  partner_parameter: /^(\w|\d){30}$/g,
  promocode: /.*/g,
  paymentId: /\d+$/g
};

export const initialState = {
  queryString: {}
};

export const saveQueryString = state => {
  // eslint-disable-next-line no-restricted-globals
  const queryStringObject = qs.parse(location.search);
  const sanitisedQueryStringObject = _sanitiseKeyValues(queryStringObject);

  //Save for later
  setState('queryString', sanitisedQueryStringObject);

  return {
    ...state,
    queryString: sanitisedQueryStringObject
  };
};

export const removeQueryString = (state, action) => {
  const next = { ...state.queryString };
  delete next[action.payload];
  return {
    ...state,
    queryString: next
  };
};

export const _sanitiseKeyValues = queryStringObject => {
  Object.keys(queryStringObject).forEach(key => {
    // eslint-disable-line
    const value = queryStringObject[key];
    const regex = validExpressions[key];

    const hasValidKeyAndValue = regex && regex.test(value);

    if (hasValidKeyAndValue) {
      queryStringObject[key] = sanitiser(value, sanitiserConfig);
      return queryStringObject[key];
    } else {
      delete queryStringObject[key];
      return;
    }
  });

  return queryStringObject;
};

export const rehydrateQueryString = (state, action) => {
  return {
    ...state,
    queryString: action.payload
  };
};

const queryStringReducer = createReducer(initialState, {
  [types.SAVE_QUERY_STRING]: saveQueryString,
  [types.REMOVE_QUERY_STRING]: removeQueryString,
  [types.REHYDRATE_QUERYSTRING]: rehydrateQueryString
});

export default queryStringReducer;
